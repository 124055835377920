import { t } from "@lingui/macro";
import { i18n } from "@lingui/core";
import { ReferenceObject } from "popper.js";
import { useState, MouseEvent } from "react";
import { Popper, Button, Paper, Typography, Box, Fade } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { info } from "src/slices/MessagesSlice";

import FlagIcon from "../../helpers/flagicon.js";
import { locales, selectLocale } from "../../locales";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import "./localesmenu.scss";

function getLocaleFlag(locale: string) {
  return locales[locale].flag;
}
const currentLocales = ["en", "zh"];

function LocaleSwitcher() {
  const dispatch = useDispatch();
  const isSmallScreen = useMediaQuery("(max-width: 680px)");

  const id = "locales-popper";
  const [anchorEl, setAnchorEl] = useState<ReferenceObject | null>(null);
  const open = Boolean(anchorEl);
  const onMouseEnter = (event: any) => {
    if (!isSmallScreen) setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClick = (event: MouseEvent) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleSelectLocale = (locale: string) => {
    if (currentLocales.includes(locale)) {
      selectLocale(locale);
    } else {
      dispatch(info(t`Coming soon`));
    }
  };
  const clickFn = (event: any) => {
    if (isSmallScreen) setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  return (
    <Box
      component="div"
      onMouseEnter={e => onMouseEnter(e)}
      onMouseLeave={e => handleClick(e)}
      onClick={e => clickFn(e)}
      id="locales-menu-button-hover"
    >
      <Button
        className="toggle-button"
        size="large"
        variant="contained"
        color="secondary"
        title={t`Change locale`}
        aria-describedby={id}
      >
        <FlagIcon code={getLocaleFlag(i18n.locale)} />
        <span>&nbsp;</span>
      </Button>

      <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-start" transition>
        {({ TransitionProps }) => {
          return (
            <Fade {...TransitionProps} timeout={100}>
              <Paper className="locales-menu" elevation={1}>
                <Box component="div">
                  {Object.keys(locales).map((locale, key) => (
                    <Button
                      key={key}
                      size="large"
                      variant="contained"
                      fullWidth
                      onClick={() => handleSelectLocale(locale)}
                    >
                      <Typography align="left">
                        &nbsp;
                        <FlagIcon code={getLocaleFlag(locale)} />
                      </Typography>
                    </Button>
                  ))}
                </Box>
              </Paper>
            </Fade>
          );
        }}
      </Popper>
    </Box>
  );
}

export default LocaleSwitcher;
