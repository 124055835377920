import { ethers } from "ethers";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { addresses } from "src/constants";
import { IJsonRPCError } from "src/slices/interfaces";
import { clearPendingTxn, fetchPendingTxns } from "src/slices/PendingTxnsSlice";
import { useWeb3Context } from ".";
import { abi as inviteABI } from "../abi/inviteABI.json";
import { error } from "../slices/MessagesSlice";
import { loadAccountDetails } from "src/slices/AccountSlice";
import { success } from "src/slices/MessagesSlice";
import { t, Trans } from "@lingui/macro";

export default function useInvite() {
  const dispatch = useDispatch();

  const { address, provider, chainID } = useWeb3Context();

  const inviteAddr = useCallback(
    async _inviteAddress => {
      let tx; // Migrate
      try {
        const signer = provider.getSigner();
        const migrationContract = new ethers.Contract(addresses[chainID].invite, inviteABI, signer);
        tx = await migrationContract.inviteAddress(_inviteAddress);
        dispatch(fetchPendingTxns({ txnHash: tx.hash, text: "inviteAddr", type: "confirm_invite" }));
        await tx.wait();
        return tx;
      } catch (e) {
        const rpcError = e;
        if (rpcError.data) {
          dispatch(error(rpcError.data.message));
        } else {
          dispatch(error(rpcError.message));
        }
      } finally {
        if (tx) {
          dispatch(clearPendingTxn(tx.hash));
          //   dispatch()
          dispatch(success(t`Success`));

          dispatch(loadAccountDetails({ networkID: chainID, address, provider }));
        }
      }
    },
    [address, provider, chainID],
  );

  return { inviteAddr };
}
