import React, { useState } from "react";
import {
  Box,
  Modal,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Tab,
  Zoom,
  Tabs,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Typography } from "@mui/material";
import "./yp.scss";
import RewardData from "src/components/RewardData/RewardData";
import TabPanel from "src/components/TabPanel";
import { t, Trans } from "@lingui/macro";
import PledgeData from "src/components/PledgeData/PledgeData";
import useStakeClaim from "src/hooks/useStakeClaim";
import useYP from "src/hooks/useYP";
import { trim } from "src/helpers";
import { useAppSelector } from "src/hooks";

function a11yProps(index: number) {
  return {
    id: `simple-tab`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function YP() {
  const isAppLoading = useAppSelector(state => state.app.loading);
  const [view, setView] = useState(0);
  const ypBalance = useAppSelector(state => state.account.ypBalance);
  const stakePending = useAppSelector(state => state.account.stakePending);
  const rewardPending = useAppSelector(state => state.account.rewardPending);

  const changeView = (_event: React.ChangeEvent<{}>, newView: number) => {
    setView(newView);
  };

  return (
    <div id="sc-view">
      <Zoom in={true}>
        <>
          <Paper className="ohm-card amount-card">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" align="center" style={{ width: "50%" }}>
                <Trans>YP amount</Trans>
              </Typography>
              <Typography variant="h4" align="center" style={{ width: "50%" }}>
                {!ypBalance ? <Skeleton width="100%" /> : <>{trim(Number(ypBalance), 4)}</>}
              </Typography>
            </Box>
          </Paper>
          <RewardData
            cliamType="claimStake"
            title={t`Staking Earnings`}
            claimTitle={t`YP Unclaimed`}
            claimAmount={stakePending}
          />
          {/* <RewardData title="Invite Reward" claimTitle="YP可领取数量" claimAmount="12,323.00" /> */}
          <Box className="tabs-box" display="flex" alignItems="center" justifyContent="flex-start">
            <Tabs
              // centered
              value={view}
              textColor="secondary"
              indicatorColor="secondary"
              className="yp-tab-buttons"
              onChange={changeView}
            >
              <Tab label={t`Invite Earnings`} {...a11yProps(0)} />
              <Tab label={t`Staking Performance`} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={view} index={0}>
            <RewardData
              cliamType="claimInvite"
              title={t`Invite Earnings`}
              claimTitle={t`YP Unclaimed`}
              claimAmount={rewardPending}
            />
          </TabPanel>
          <TabPanel value={view} index={1}>
            <PledgeData />
          </TabPanel>
        </>
      </Zoom>
    </div>
  );
}

export default YP;
