import React, { useEffect, useState } from "react";
import "./pledgeData.scss";
import {
  Box,
  Modal,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Trans } from "@lingui/macro";
import { Typography } from "@mui/material";
import { useMediaQuery } from "@material-ui/core";
import { useWeb3Context } from "src/hooks";
import { shorten, trim } from "src/helpers";
import { ethers } from "ethers";

function PledgeData() {
  const isSmallScreen = useMediaQuery("(max-width:680px)");
  const { address } = useWeb3Context();

  const [data, setData] = useState({});

  useEffect(() => {
    if (address) {
      try {
        fetch(`https://abi.yapdao.finance/node?address=${address}`)
          .then(response => response.json())
          .then(data => {
            console.log(data);
            setData(data.result);
          });
      } catch (error) {
        console.log(error);
      }
    }
  }, [address]);

  return (
    <>
      <Paper className="ohm-card pledge-data-card">
        <Box
          display="flex"
          flexDirection={isSmallScreen ? "column" : "row"}
          alignItems="center"
          justifyContent="space-between"
          className="pledge-data-box"
        >
          <Box alignItems="center" style={{ width: "100%", marginBottom: isSmallScreen ? "10px" : "0" }}>
            <Typography variant={isSmallScreen ? "h6" : "h5"} align="center" style={{ color: "#676B74" }}>
              <Trans>Number</Trans>
            </Typography>
            <Typography
              variant={isSmallScreen ? "h5" : "h4"}
              align="center"
              style={{ color: "#181A1D", fontWeight: "500" }}
            >
              {data && data.teamNum ? data.teamNum : 0}
            </Typography>
          </Box>
          <Box alignItems="center" style={{ width: "100%" }}>
            <Typography variant={isSmallScreen ? "h6" : "h5"} align="center" style={{ color: "#676B74" }}>
              <Trans>Staking Performance(DR)</Trans>
            </Typography>
            <Typography
              variant={isSmallScreen ? "h5" : "h4"}
              align="center"
              style={{ color: "#181A1D", fontWeight: "500" }}
            >
              {data && data.totalUnity ? trim(ethers.utils.formatUnits(data.totalUnity, 9), 4) : 0}
            </Typography>
          </Box>
        </Box>
      </Paper>
      <Paper className="ohm-card pledge-data-table">
        <TableContainer>
          <Table aria-label="pledge data">
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="h6">
                    <Trans>Address</Trans>
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6">
                    <Trans>Staking Amount</Trans>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.list &&
                data.list.length > 0 &&
                data.list.map((val, i) => (
                  <TableRow key={i}>
                    <TableCell align="left">{shorten(val.address)}</TableCell>
                    <TableCell align="right">
                      {val.amount > 0 ? trim(ethers.utils.formatUnits(val.amount, 9), 4) : 0}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  );
}

export default PledgeData;
