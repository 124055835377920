import { useCallback, useState, ChangeEvent, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Tab,
  Tabs,
  Zoom,
  Divider,
  TextField,
  SvgIcon,
} from "@material-ui/core";
import { Typography } from "@mui/material";

import { t, Trans } from "@lingui/macro";
import NewReleases from "@material-ui/icons/NewReleases";
import RebaseTimer from "../../components/RebaseTimer/RebaseTimer";
import TabPanel from "../../components/TabPanel";
import { getOhmTokenImage, getTokenImage, trim, toolNumber } from "../../helpers";
import { changeApproval, changeStake, cliamWarmupBalance } from "../../slices/StakeThunk";
import "./stake.scss";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@material-ui/lab";
import ExternalStakePool from "./ExternalStakePool";
import { error } from "../../slices/MessagesSlice";
import { ethers } from "ethers";
import { useAppSelector } from "src/hooks";
import useInvite from "src/hooks/useInvite";
import useBonds, { IAllBondData } from "../../hooks/Bonds";
import { ReactComponent as RightIcon } from "src/assets/icons/right.svg";
import { LoadingButton } from "@mui/lab";

type InputEvent = ChangeEvent<HTMLInputElement>;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const sOhmImg = getTokenImage("sXPH");
const ohmImg = getOhmTokenImage(16, 16);

function Stake() {
  const dispatch = useDispatch();
  const { provider, address, connected, connect, chainID } = useWeb3Context();
  const { inviteAddr } = useInvite();
  const { bonds } = useBonds(chainID);
  const xphBond: any = bonds.filter(_ => _.name === "xph_busd_lp")[0];
  const xphPrice = xphBond && xphBond["marketPrice"];

  const [zoomed, setZoomed] = useState(false);
  const [isInvited, setIsInvited] = useState(false);
  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState<any>();
  const [inviteAddress, setInviteAddress] = useState<string>();

  const isAppLoading = useAppSelector(state => state.app.loading);
  const currentIndex = useAppSelector(state => {
    return state.app.currentIndex;
  });
  const yieldAmount = useAppSelector(state => {
    return state.account.yieldAmount;
  });

  const fiveDayRate = useAppSelector(state => {
    return state.app.fiveDayRate;
  });

  const isActivecode = useAppSelector(state => {
    return state.account.isActivecode;
  });
  const ohmBalance = useAppSelector(state => {
    return state.account.balances.ohm;
  });
  const gonsBal = useAppSelector(state => {
    return state.account.balances.gonsBal || 0;
  });

  // console.log("gonsBal", gonsBal);

  const sohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.sXPH;
  });
  const fsohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.fsohm;
  });
  const wsohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.wsohm;
  });
  const wsohmAsSohm = useAppSelector(state => {
    return state.account.balances && state.account.balances.wsohmAsSohm;
  });
  const stakeAllowance = useAppSelector(state => {
    return (state.account.staking && state.account.staking.ohmStake) || 0;
  });

  const unstakeAllowance = useAppSelector(state => {
    return (state.account.staking && state.account.staking.ohmUnstake) || 0;
  });
  const stakingRebase = useAppSelector(state => {
    return state.app.stakingRebase || 0;
  });
  const stakingAPY = useAppSelector(state => {
    // return state.app.stakingAPY || 0;
    return state.app.stakingAPY ? toolNumber(state.app.stakingAPY) : 0;
  });

  const stakingTVL = useAppSelector(state => {
    return state.app.stakingTVL;
  });
  const pendingTransactions = useAppSelector(state => {
    return state.pendingTransactions;
  });

  useEffect(() => {
    setIsInvited(isActivecode);
  }, [isActivecode]);

  const setMax = () => {
    if (view === 0) {
      setQuantity(Number(ohmBalance));
    } else {
      setQuantity(Number(yieldAmount / Math.pow(10, 9)));
    }
  };

  const onSeekApproval = async (token: string) => {
    await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
  };

  const onChangeStake = async (action: string) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(quantity) || quantity === 0) {
      // eslint-disable-next-line no-alert
      return dispatch(error(t`Please enter a value!`));
    }

    // 1st catch if quantity > balance
    let gweiValue = ethers.utils.parseUnits(quantity.toString(), "gwei");
    if (action === "stake" && gweiValue.gt(ethers.utils.parseUnits(ohmBalance, "gwei"))) {
      return dispatch(error(t`You cannot stake more than your DR balance.`));
    }

    if (
      action === "unstake" &&
      gweiValue.gt(ethers.utils.parseUnits((yieldAmount / Math.pow(10, 9)).toString(), "gwei"))
    ) {
      return dispatch(error(t`You cannot unstake more than your sDR balance.`));
    }

    await dispatch(changeStake({ address, action, value: quantity.toString(), provider, networkID: chainID }));
    setQuantity(0);
  };

  const hasAllowance = useCallback(
    token => {
      if (token === "ohm") return stakeAllowance > 0;
      if (token === "sXPH") return unstakeAllowance > 0;
      return 0;
    },
    [stakeAllowance, unstakeAllowance],
  );

  const isAllowanceDataLoading = (stakeAllowance == null && view === 0) || (unstakeAllowance == null && view === 1);

  let modalButton = [];

  modalButton.push(
    <Button variant="contained" color="primary" className="connect-button" onClick={connect} key={1}>
      <Trans>Connect Wallet</Trans>
    </Button>,
  );

  const changeView = (_event: React.ChangeEvent<{}>, newView: number) => {
    setView(newView);
    (quantity || quantity == 0) && setQuantity("");
  };

  const trimmedBalance = Number(
    [sohmBalance, fsohmBalance, wsohmAsSohm]
      .filter(Boolean)
      .map(balance => Number(balance))
      .reduce((a, b) => a + b, 0)
      .toFixed(4),
  );
  const trimmedStakingAPY = trim(stakingAPY * 100, 5);
  const stakingRebasePercentage = trim(stakingRebase * 100, 4);
  const nextRewardValue = trim((Number(stakingRebasePercentage) / 100) * (trimmedBalance + Number(gonsBal)), 4);
  const formatGonsBal = trim(Number(gonsBal), 4);
  const claimWarmupBal = () => {
    dispatch(cliamWarmupBalance({ address, provider, networkID: chainID }));
  };

  const onInviteAddressChange = (e: InputEvent): void => {
    if (!e.target.value) setInviteAddress(address);
    return setInviteAddress(e.target.value);
  };

  const confirmInvite = async () => {
    await inviteAddr(inviteAddress);
  };

  useEffect(() => {
    if (location.search.indexOf("invite") > -1) {
      let obj: any = {};
      let params = location.search.substring(1).split("&");

      params.map(_ => {
        obj[_.split("=")[0]] = _.split("=")[1];
      });
      setInviteAddress(obj.invite);
    }
  }, []);

  return (
    <div id="stake-view">
      <Zoom in={true} onEntered={() => setZoomed(true)}>
        {address && !isInvited ? (
          <Paper className="ohm-card">
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <div className="card-header">
                  <Typography variant="h5">
                    <Trans>Bind the invitation ID</Trans>
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <Typography variant="body1" style={{ marginBottom: "20px" }}>
                  <Trans>Address</Trans>
                </Typography>
                <TextField
                  type="text"
                  className="invite-address-ipt"
                  placeholder={t`Address`}
                  value={inviteAddress}
                  onChange={onInviteAddressChange}
                />
                <Box className="invite-address-right">
                  <SvgIcon component={RightIcon} />
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={!inviteAddress || isPendingTxn(pendingTransactions, "confirm_invite")}
                    loading={isPendingTxn(pendingTransactions, "confirm_invite")}
                    // disabled={!inviteAddress}
                    // loading
                    loadingPosition="end"
                    onClick={confirmInvite}
                    className="invite-btn"
                  >
                    {txnButtonText(pendingTransactions, "confirm_invite", t`Confirm`)}
                  </LoadingButton>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        ) : (
          <Paper className={`ohm-card`}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <div className="card-header">
                  <Typography variant="h5">Single Stake (3, 3)</Typography>
                  <RebaseTimer />

                  {/* {address && Number(oldSohmBalance) > 0.01 && (
                  <Link
                    className="migrate-sXPH-button"
                    style={{ textDecoration: "none" }}
                    href="https://docs.olympusdao.finance/using-the-website/migrate"
                    aria-label="migrate-sXPH"
                    target="_blank"
                  >
                    <NewReleases viewBox="0 0 24 24" />
                    <Typography>
                      <Trans>MigratesDR!</Trans>
                    </Typography>
                  </Link>
                )} */}
                </div>
              </Grid>

              <Grid item>
                <div className="stake-top-metrics">
                  <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="stake-apy">
                        <Typography variant="h5" color="textSecondary">
                          <Trans>APY</Trans>
                        </Typography>
                        <Typography variant="h4">
                          {stakingAPY ? (
                            // stakingAPY == 0 ? (
                            //   "--"
                            // ) : (
                            <span data-testid="apy-value">
                              {new Intl.NumberFormat("en-US").format(Number(trimmedStakingAPY))}%
                            </span>
                          ) : (
                            // )
                            <Skeleton width="150px" data-testid="apy-loading" />
                          )}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="stake-tvl">
                        <Typography variant="h5" color="textSecondary">
                          <Trans>Total Value Deposited</Trans>
                        </Typography>
                        <Typography variant="h4">
                          {stakingTVL ? (
                            // (
                            // stakingTVL == 0 ? (
                            //   "--"
                            // ) :
                            <span data-testid="tvl-value">
                              {new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                                maximumFractionDigits: 0,
                                minimumFractionDigits: 0,
                              }).format(stakingTVL)}
                            </span>
                          ) : (
                            // )
                            <Skeleton width="150px" data-testid="tvl-loading" />
                          )}
                        </Typography>
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4}>
                      <div className="stake-index">
                        <Typography variant="h5" color="textSecondary">
                          <Trans>Current Index</Trans>
                        </Typography>
                        <Typography variant="h4">
                          {currentIndex ? (
                            <span data-testid="index-value">{trim(Number(currentIndex), 2)}DR</span>
                          ) : (
                            <Skeleton width="150px" data-testid="index-loading" />
                          )}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>

              <div className="staking-area">
                {!address ? (
                  <div className="stake-wallet-notification">
                    <div className="wallet-menu" id="wallet-menu">
                      {modalButton}
                    </div>
                    <Typography variant="h6">
                      <Trans>Connect your wallet to stake DR</Trans>
                    </Typography>
                  </div>
                ) : (
                  <>
                    <Box className="stake-action-area">
                      <Tabs
                        key={String(zoomed)}
                        centered
                        value={view}
                        textColor="primary"
                        indicatorColor="primary"
                        className="stake-tab-buttons"
                        onChange={changeView}
                        aria-label="stake tabs"
                        //hides the tab underline sliding animation in while <Zoom> is loading
                        TabIndicatorProps={!zoomed ? { style: { display: "none" } } : undefined}
                      >
                        <Tab
                          label={t({
                            id: "Stake",
                            comment: "The action of staking (verb)",
                          })}
                          {...a11yProps(0)}
                        />
                        <Tab label={t`Unstake`} {...a11yProps(1)} />
                      </Tabs>
                      <Box className="stake-action-row " display="flex" alignItems="center">
                        {address && !isAllowanceDataLoading ? (
                          (!hasAllowance("ohm") && view === 0) || (!hasAllowance("sXPH") && view === 1) ? (
                            <Box className="help-text">
                              <Typography variant="body1" className="stake-note" color="textSecondary">
                                {view === 0 ? (
                                  <>
                                    <Trans>First time staking</Trans> <b>DR</b>?
                                    <br />
                                    <Trans>Please approve YAP DAO to use your</Trans> <b>DR</b>{" "}
                                    <Trans>for staking</Trans>.
                                  </>
                                ) : (
                                  <>
                                    <Trans>First time unstaking</Trans> <b>sDR</b>?
                                    <br />
                                    <Trans>Please approve YAP DAO to use your</Trans> <b>sDR</b>{" "}
                                    <Trans>for unstaking</Trans>.
                                  </>
                                )}
                              </Typography>
                            </Box>
                          ) : (
                            <FormControl className="ohm-input" variant="outlined" color="primary">
                              {/* <InputLabel htmlFor="amount-input"></InputLabel> */}
                              <OutlinedInput
                                id="amount-input"
                                type="number"
                                placeholder="Enter an amount"
                                className="stake-input"
                                value={quantity}
                                onChange={e => setQuantity(e.target.value)}
                                labelWidth={0}
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Button variant="text" onClick={setMax} color="inherit">
                                      Max
                                    </Button>
                                  </InputAdornment>
                                }
                              />
                            </FormControl>
                          )
                        ) : (
                          <Skeleton width="150px" />
                        )}

                        <TabPanel value={view} index={0} className="stake-tab-panel">
                          {isAllowanceDataLoading ? (
                            <Skeleton />
                          ) : address && hasAllowance("ohm") ? (
                            <LoadingButton
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "staking")}
                              loading={isPendingTxn(pendingTransactions, "staking")}
                              loadingPosition="end"
                              onClick={() => {
                                onChangeStake("stake");
                              }}
                            >
                              {txnButtonText(pendingTransactions, "staking", t`StakeDR`)}
                            </LoadingButton>
                          ) : (
                            <LoadingButton
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "approve_staking")}
                              loading={isPendingTxn(pendingTransactions, "approve_staking")}
                              loadingPosition="end"
                              onClick={() => {
                                onSeekApproval("ohm");
                              }}
                            >
                              {txnButtonText(pendingTransactions, "approve_staking", t`Approve`)}
                            </LoadingButton>
                          )}
                        </TabPanel>
                        <TabPanel value={view} index={1} className="stake-tab-panel">
                          {isAllowanceDataLoading ? (
                            <Skeleton />
                          ) : address && hasAllowance("sXPH") ? (
                            <LoadingButton
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "unstaking")}
                              loading={isPendingTxn(pendingTransactions, "unstaking")}
                              loadingPosition="end"
                              onClick={() => {
                                onChangeStake("unstake");
                              }}
                            >
                              {txnButtonText(pendingTransactions, "unstaking", t`UnstakeDR`)}
                            </LoadingButton>
                          ) : (
                            <LoadingButton
                              className="stake-button"
                              variant="contained"
                              color="primary"
                              disabled={isPendingTxn(pendingTransactions, "approve_unstaking")}
                              loading={isPendingTxn(pendingTransactions, "approve_unstaking")}
                              loadingPosition="end"
                              onClick={() => {
                                onSeekApproval("sXPH");
                              }}
                            >
                              {txnButtonText(pendingTransactions, "approve_unstaking", t`Approve`)}
                            </LoadingButton>
                          )}
                        </TabPanel>
                      </Box>
                    </Box>

                    <div className={`stake-user-data`}>
                      <div className="data-row">
                        <Typography variant="body1">
                          <Trans>Unstaked Balance</Trans>
                        </Typography>
                        <Typography variant="body1" id="user-balance">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{trim(Number(ohmBalance), 4)}DR</>}
                        </Typography>
                      </div>

                      <div className="data-row">
                        <Typography variant="body1">
                          <Trans>Staked Balance</Trans>
                        </Typography>
                        <Typography variant="body1" id="user-staked-balance">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{trim(yieldAmount / Math.pow(10, 9), 4)}sDR</>}
                        </Typography>
                      </div>

                      <div className="data-row">
                        <Typography variant="body1">
                          <Trans>Yield Amount</Trans>
                        </Typography>
                        <div className="warmup-container">
                          {isAppLoading ? (
                            <Skeleton width="80px" />
                          ) : (
                            <>
                              <div className="warmup-container">
                                {trim(Number(sohmBalance) - yieldAmount / Math.pow(10, 9), 4)}sDR
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <Divider color="secondary" />

                      <div className="data-row">
                        <Typography variant="body1">
                          <Trans>Next Reward Amount</Trans>
                        </Typography>
                        <Typography variant="body1">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{nextRewardValue}sDR</>}
                        </Typography>
                      </div>

                      <div className="data-row">
                        <Typography variant="body1">
                          <Trans>Next Reward Yield</Trans>
                        </Typography>
                        <Typography variant="body1">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{stakingRebasePercentage}%</>}
                        </Typography>
                      </div>

                      <div className="data-row">
                        <Typography variant="body1">
                          <Trans>ROI (5-Day Rate)</Trans>
                        </Typography>
                        <Typography variant="body1">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{trim(Number(fiveDayRate) * 100, 4)}%</>}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </Grid>
          </Paper>
        )}
      </Zoom>

      {/* <ExternalStakePool /> */}
    </div>
  );
}

export default Stake;
