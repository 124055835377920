import { memo } from "react";
import "./treasury-dashboard.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { Skeleton } from "@material-ui/lab";
import { Paper, Grid, Box, Zoom, Container, useMediaQuery, Typography } from "@material-ui/core";
import {
  MarketCap,
  OHMPrice,
  SOHMPrice,
  CircSupply,
  BackingPerOHM,
  TotalSupply,
  CurrentIndex,
} from "./components/Metric/Metric";
import { useAppSelector } from "src/hooks";
import { formatCurrency, toolNumber, trim } from "../../helpers";
import { allBondsMap } from "src/helpers/AllBonds";
import Chart from "src/components/Chart/Chart.jsx";

import {
  TotalValueDepositedGraph,
  MarketValueGraph,
  RiskFreeValueGraph,
  ProtocolOwnedLiquidityGraph,
  OHMStakedGraph,
  APYOverTimeGraph,
  RunwayAvailableGraph,
} from "./components/Graph/Graph";

const TreasuryDashboard = memo(() => {
  const isSmallScreen = useMediaQuery("(max-width: 650px)");
  const isVerySmallScreen = useMediaQuery("(max-width: 480px)");
  const isAppLoading: boolean = useAppSelector(state => state.app.loading);

  const marketPrice = useAppSelector(state => {
    return state.app.marketPrice;
  });

  const stakingTVL = marketPrice as number;

  const stakingAPY = useAppSelector(state => {
    return state.app.stakingAPY ? toolNumber(state.app.stakingAPY) : 0;
  });

  const StakeRate: number | undefined = useAppSelector(state => {
    return state.app.StakeRate;
  });
  const trimmedStakingAPY = trim(stakingAPY * 100, 2);
  const currentIndex = useAppSelector(state => {
    return state.app.currentIndex;
  });
  const totalReserves = useAppSelector(state => {
    return state.app.totalReserves;
  });

  return (
    <div id="treasury-dashboard-view" className={`${isSmallScreen && "smaller"} ${isVerySmallScreen && "very-small"}`}>
      <Container
        style={{
          paddingLeft: isVerySmallScreen ? "0.5rem" : isSmallScreen ? "3rem" : "8rem",
          paddingRight: isVerySmallScreen ? "0.5rem" : isSmallScreen ? "3rem" : "8rem",
        }}
      >
        <Box className="hero-metrics">
          <Paper className="ohm-card">
            <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
              <MarketCap />
              <OHMPrice />
              <SOHMPrice />
              <TotalSupply />
              <BackingPerOHM />
              <CurrentIndex />
            </Box>
          </Paper>
        </Box>

        <Zoom in={true}>
          <Grid container spacing={2} className="data-grid">
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <TotalValueDepositedGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <RiskFreeValueGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <ProtocolOwnedLiquidityGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <OHMStakedGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <RunwayAvailableGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <MarketValueGraph />
              </Paper>
            </Grid>
            {/* <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card ohm-chart-card">
                <MarketValueGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card ohm-chart-card">
                <RiskFreeValueGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <ProtocolOwnedLiquidityGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <OHMStakedGraph />
              </Paper>
            </Grid>

            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Paper className="ohm-card">
                <RunwayAvailableGraph />
              </Paper>
            </Grid> */}
          </Grid>
        </Zoom>
      </Container>
    </div>
  );
});

const queryClient = new QueryClient();

// Normally this would be done
// much higher up in our App.
export default () => (
  <QueryClientProvider client={queryClient}>
    <TreasuryDashboard />
  </QueryClientProvider>
);
