import { StableBond, LPBond, NetworkID, CustomBond, BondType } from "src/lib/Bond";
import { addresses } from "src/constants";
import { abi as BondOhmLusdHelperContract } from "src/abi/BondHelper.json";

import { ReactComponent as BusdImg } from "src/assets/tokens/BUSD.svg";
import { ReactComponent as USDT } from "src/assets/tokens/USDT.svg";
import { ReactComponent as DRUSDTdImg } from "src/assets/tokens/dr_usdt.svg";
import { ReactComponent as FraxImg } from "src/assets/tokens/FRAX.svg";
import { ReactComponent as OhmFraxImg } from "src/assets/tokens/OHM-FRAX.svg";
import { ReactComponent as OhmLusdImg } from "src/assets/tokens/OHM-LUSD.svg";
import { ReactComponent as OhmEthImg } from "src/assets/tokens/OHM-WETH.svg";
import { ReactComponent as wETHImg } from "src/assets/tokens/wETH.svg";
import { ReactComponent as LusdImg } from "src/assets/tokens/LUSD.svg";
import { ReactComponent as CvxImg } from "src/assets/tokens/CVX.svg";

import { abi as FraxOhmBondContract } from "src/abi/bonds/OhmFraxContract.json";
import { abi as BondOhmDaiContract } from "src/abi/bonds/OhmDaiContract.json";
import { abi as BondOhmLusdContract } from "src/abi/bonds/OhmLusdContract.json";
import { abi as BondOhmEthContract } from "src/abi/bonds/OhmEthContract.json";

import { abi as DaiBondContract } from "src/abi/bonds/DaiContract.json";
import { abi as ReserveOhmLusdContract } from "src/abi/reserves/OhmLusd.json";
import { abi as ReserveOhmDaiContract } from "src/abi/reserves/OhmDai.json";
import { abi as ReserveOhmFraxContract } from "src/abi/reserves/OhmFrax.json";
import { abi as ReserveOhmEthContract } from "src/abi/reserves/OhmEth.json";

import { abi as FraxBondContract } from "src/abi/bonds/FraxContract.json";
import { abi as LusdBondContract } from "src/abi/bonds/LusdContract.json";
import { abi as EthBondContract } from "src/abi/bonds/EthContract.json";
import { abi as CvxBondContract } from "src/abi/bonds/CvxContract.json";

import { abi as ierc20Abi } from "src/abi/IERC20.json";
import { getBondCalculator } from "src/helpers/BondCalculator";
import { BigNumberish } from "ethers";
import { getTokenPrice } from "src/helpers";

// TODO(zx): Further modularize by splitting up reserveAssets into vendor token definitions
//   and include that in the definition of a bond
export const usdt = new StableBond({
  name: "usdt",
  displayName: "USDT",
  bondToken: "USDT",
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: true },
  bondIconSvg: USDT,
  bondContractABI: BondOhmDaiContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x29Af288A7364d16354Dac12cd8db93846E14f636",
      reserveAddress: "0x55d398326f99059ff775485246999027b3197955",
      bondHelperAddress: "0x29Af288A7364d16354Dac12cd8db93846E14f636",
    },
    [NetworkID.Testnet]: {
      bondAddress: "",
      reserveAddress: "",
    },
  },
});

// // the old convex bonds. Just need to be claimable for the users who previously purchased
export const cvx_expired = new CustomBond({
  name: "cvx-v1",
  displayName: "CVX OLD",
  lpUrl: "",
  bondType: BondType.StableAsset,
  bondToken: "CVX",
  isAvailable: { [NetworkID.Mainnet]: false, [NetworkID.Testnet]: true },
  bondHelperContractABI: BondOhmLusdHelperContract,
  bondIconSvg: CvxImg,
  bondContractABI: CvxBondContract,
  reserveContract: ierc20Abi, // The Standard ierc20Abi since they're normal tokens
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x6754c69fe02178f54ADa19Ebf1C5569826021920",
      reserveAddress: "0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B",
      bondHelperAddress: "0xe7e2c409944490974b0e634ea6f2af1297e44a52",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xd43940687f6e76056789d00c43A40939b7a559b5",
      reserveAddress: "0xB2180448f8945C8Cc8AE9809E67D6bd27d8B2f2C", // using DAI per `principal` address
      // reserveAddress: "0x6761Cb314E39082e08e1e697eEa23B6D1A77A34b", // guessed
      bondHelperAddress: "0xe7e2c409944490974b0e634ea6f2af1297e44a52",
    },
  },
  customTreasuryBalanceFunc: async function (this: CustomBond, networkID, provider) {
    let cvxPrice: number = await getTokenPrice("convex-finance");
    const token = this.getContractForReserve(networkID, provider);
    let cvxAmount: BigNumberish = await token.balanceOf(addresses[networkID].TREASURY_ADDRESS);
    cvxAmount = Number(cvxAmount.toString()) / Math.pow(10, 18);
    return cvxAmount * cvxPrice;
  },
});

export const ohm_dai = new LPBond({
  name: "dr_usdt_lp",
  displayName: "DR-USDT LP",
  bondToken: "USDT",
  isAvailable: { [NetworkID.Mainnet]: true, [NetworkID.Testnet]: false },
  bondIconSvg: DRUSDTdImg,
  bondContractABI: BondOhmDaiContract,
  reserveContract: ReserveOhmDaiContract,
  bondHelperContractABI: BondOhmLusdHelperContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0x857469B46c43B8415C95e90e4050B82650B149E2",
      reserveAddress: "0x62eA391404325e003641FF15f8dC792358C0e33D",
      bondHelperAddress: "0x9a92E329736Eb6a79875eBe64005A0eEd1f20156",
    },
    [NetworkID.Testnet]: {
      bondAddress: "0xc96554F5E1325074B4D909d971beAeD72Ef46F3D",
      reserveAddress: "0x7Cb35D0508Ea196c31293c9785f8cd4d3886B8e2",
      bondHelperAddress: "0xb80931cF8570c950c049E1027C67220BF6397625",
    },
  },
  lpUrl: "",
  // "https://pancake.kiemtienonline360.com/#/add/0x22A5A72801997fB54C11c9478481bbB6cCED43ae/0xAACbceA4923DF9E6D6a14F1Cd7752461C657dE2e",
  // "https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x3e29633846E8b857B7f6d7f293F895186804264b",
  // "https://pancake.kiemtienonline360.com/#/add/0x787096Db656E5339437d9308220286Ee97641680/0xB2cc13E71026Ff3CC93c8CE648C04791d229EaB0",
});

export const ohm_lusd = new LPBond({
  name: "ohm_lusd_lp",
  displayName: "XPH-LUSD LP",
  bondToken: "LUSD",
  isAvailable: { [NetworkID.Mainnet]: false, [NetworkID.Testnet]: true },
  bondIconSvg: OhmLusdImg,
  bondContractABI: BondOhmLusdContract,
  reserveContract: ReserveOhmLusdContract,
  bondHelperContractABI: BondOhmLusdHelperContract,
  networkAddrs: {
    [NetworkID.Mainnet]: {
      bondAddress: "0xFB1776299E7804DD8016303Df9c07a65c80F67b6",
      reserveAddress: "0xfDf12D1F85b5082877A6E070524f50F6c84FAa6b",
      bondHelperAddress: "0xfDf12D1F85b5082877A6E070524f50F6c84FAa6b",
    },
    [NetworkID.Testnet]: {
      // NOTE (appleseed-lusd): using ohm-dai rinkeby contracts
      bondAddress: "0xC26bDd3Ce6B30eC44CB5FbD0A24F91F20C32C827",
      reserveAddress: "0x8D5a22Fb6A1840da602E56D1a260E56770e0bCE2",
      bondHelperAddress: "0x8D5a22Fb6A1840da602E56D1a260E56770e0bCE2",
    },
  },
  lpUrl:
    "https://app.sushi.com/add/0x383518188C0C6d7730D91b2c03a03C837814a899/0x5f98805A4E8be255a32880FDeC7F6728C6568bA0",
});

export const allBonds = [ohm_dai, usdt];
// export const allBonds = [ohm_dai, busd];
// TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
// export const allExpiredBonds = [];
export const allExpiredBonds = [cvx_expired];
export const allBondsMap = allBonds.reduce((prevVal, bond) => {
  return { ...prevVal, [bond.name]: bond };
}, {});

// Debug Log
export default allBonds;
