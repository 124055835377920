import { ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";

import { useEffect, useState, useCallback } from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import useTheme from "./hooks/useTheme";
import useBonds, { IAllBondData } from "./hooks/Bonds";
import { useAddress, useWeb3Context } from "./hooks/web3Context";
import useSegmentAnalytics from "./hooks/useSegmentAnalytics";
import { segmentUA } from "./helpers/userAnalyticHelpers";
import { shouldTriggerSafetyCheck } from "./helpers";
import { ethers } from "ethers";

import { calcBondDetails } from "./slices/BondSlice";
import { loadAppDetails } from "./slices/AppSlice";
import { loadAccountDetails, calculateUserBondDetails, getBalances } from "./slices/AccountSlice";
import { info } from "./slices/MessagesSlice";
import { Stake, ChooseBond, Bond, TreasuryDashboard, YP, ClaimProfit, DAORewards } from "./views";
import Sidebar from "./components/Sidebar/Sidebar.jsx";
import TopBar from "./components/TopBar/TopBar.jsx";
import NavDrawer from "./components/Sidebar/NavDrawer.jsx";
import Messages from "./components/Messages/Messages";
import Footer from "./components/Footer/Footer";
import NotFound from "./views/404/NotFound";

import { dark as darkTheme } from "./themes/dark.js";
import { light as lightTheme } from "./themes/light.js";
import { girth as gTheme } from "./themes/girth.js";
import muiTheme from "./themes/muiGlobal";

import { useAppSelector } from "src/hooks";
import "./style.scss";
// import { Bond as IBond } from "./lib/Bond";
// import { useGoogleAnalytics } from "./hooks/useGoogleAnalytics";

// 😬 Sorry for all the console logging
const DEBUG = false;

// 🛰 providers
if (DEBUG) console.log("📡 Connecting to Mainnet Ethereum");
// 🔭 block explorer URL
// const blockExplorer = targetNetwork.blockExplorer;

const drawerWidth = 280;
const transitionDuration = 969;

function App() {
  // useSegmentAnalytics();
  // useGoogleAnalytics();
  const [isInvited, setIsInvited] = useState(false);

  const location = useLocation();
  const [isLoad, setIsLoad] = useState(false);
  const dispatch = useDispatch();
  const [theme, toggleTheme, mounted] = useTheme();
  const currentPath = location.pathname + location.search + location.hash;

  const useStyles = makeStyles(theme => ({
    drawer: {
      [theme.breakpoints.up("md")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: transitionDuration,
      }),
      height: "100%",
      overflow: "auto",
      marginLeft: drawerWidth,
      position: "relative",
      // background: ,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: transitionDuration,
      }),
      marginLeft: 0,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
  }));
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmallerScreen = useMediaQuery("(max-width: 980px)");
  const isSmallScreen = useMediaQuery("(max-width: 600px)");
  const classes = useStyles();
  const { connect, hasCachedProvider, provider, chainID, connected } = useWeb3Context();
  const isActivecode = useAppSelector(state => {
    return state.account.isActivecode;
  });
  const address = useAddress();

  const [walletChecked, setWalletChecked] = useState(false);

  // TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
  const { bonds } = useBonds(chainID);

  async function loadDetails(whichDetails: string) {
    // NOTE (unbanksy): If you encounter the following error:
    // Unhandled Rejection (Error): call revert exception (method="balanceOf(address)", errorArgs=null, errorName=null, errorSignature=null, reason=null, code=CALL_EXCEPTION, version=abi/5.4.0)
    // it's because the initial provider loaded always starts with chainID=1. This causes
    // address lookup on the wrong chain which then throws the error. To properly resolve this,
    // we shouldn't be initializing to chainID=1 in web3Context without first listening for the
    // network. To actually test rinkeby, change setChainID equal to 4 before testing.
    let loadProvider = provider;

    if (whichDetails === "app") {
      // loadApp(loadProvider);
      // const providerUrl =
      //   window.location.hostname == ""
      //     ? "https://bsc-dataseed1.binance.org/"
      //     : "https://data-seed-prebsc-1-s1.binance.org:8545/";
      loadApp(new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.binance.org/"));
      // loadApp(loadProvider);
    }

    // don't run unless provider is a Wallet...
    if (whichDetails === "account" && address && connected && isLoad) {
      loadAccount(loadProvider);
    }
  }

  const loadApp = useCallback(
    loadProvider => {
      dispatch(loadAppDetails({ networkID: chainID, provider: loadProvider }));

      bonds.map(bond => {
        dispatch(calcBondDetails({ bond, value: "", provider: loadProvider, networkID: chainID }));
      });
      setIsLoad(true);
    },
    [connected],
  );

  const loadAccount = useCallback(
    loadProvider => {
      dispatch(loadAccountDetails({ networkID: chainID, address, provider: loadProvider }));
      dispatch(getBalances({ networkID: chainID, address, provider: loadProvider }));

      bonds.map(bond => {
        dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
      });
      // expiredBonds.map(bond => {
      //   dispatch(calculateUserBondDetails({ address, bond, provider, networkID: chainID }));
      // });
    },
    [connected],
  );

  // The next 3 useEffects handle initializing API Loads AFTER wallet is checked
  //
  // this useEffect checks Wallet Connection & then sets State for reload...
  // ... we don't try to fire Api Calls on initial load because web3Context is not set yet
  // ... if we don't wait we'll ALWAYS fire API calls via JsonRpc because provider has not
  // ... been reloaded within App.
  useEffect(() => {
    if (hasCachedProvider()) {
      // then user DOES have a wallet
      setWalletChecked(true);
      connect()
        .then(() => {
          // segmentUA({
          //   type: "connect",
          //   provider: provider,
          //   context: currentPath,
          // });
        })
        .catch(err => {
          setWalletChecked(true);
        });
    } else {
      // then user DOES NOT have a wallet
      setWalletChecked(true);
    }
    // if (shouldTriggerSafetyCheck()) {
    //   dispatch(info("Safety Check: Always verify you're on !"));
    // }
  }, [chainID, address]);

  // this useEffect fires on state change from above. It will ALWAYS fire AFTER
  useEffect(() => {
    // don't load ANY details until wallet is Checked

    if (walletChecked) {
      loadDetails("app");
    }
  }, [walletChecked, chainID, location]);

  // this useEffect picks up any time a user Connects via the button
  useEffect(() => {
    // don't load ANY details until wallet is Connected
    if (connected && isLoad) {
      loadDetails("account");
    }
  }, [connected, isLoad, chainID, address, location]);

  useEffect(() => {
    setIsInvited(isActivecode);
  }, [isActivecode]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleSidebarClose = () => {
    setIsSidebarExpanded(false);
  };

  let themeMode = lightTheme;

  useEffect(() => {
    themeMode = lightTheme;
  }, [theme]);

  useEffect(() => {
    if (isSidebarExpanded) handleSidebarClose();
  }, [location]);

  return (
    <ThemeProvider theme={themeMode}>
      <MuiThemeProvider theme={muiTheme}>
        <CssBaseline />
        {/* {isAppLoading && <LoadingSplash />} */}
        <div className={`app ${isSmallerScreen && "tablet"} ${isSmallScreen && "mobile"} ${theme} `}>
          <Messages />
          <TopBar theme={theme} toggleTheme={toggleTheme} handleDrawerToggle={handleDrawerToggle} />
          <nav className={classes.drawer}>
            {isSmallerScreen ? (
              <NavDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
            ) : (
              <Sidebar />
            )}
          </nav>

          <div className={`${classes.content} ${isSmallerScreen && classes.contentShift}`}>
            <Switch>
              <Route exact path="/dashboard">
                <TreasuryDashboard />
              </Route>

              <Route path="/stake">
                <Stake />
              </Route>
              <Route path="/yp">
                <YP />
              </Route>
              <Route path="/claimprofit">
                <ClaimProfit />
              </Route>
              <Route path="/daorewards">
                <DAORewards />
              </Route>

              <Route path="/bonds">
                {!(address && !isInvited) &&
                  (bonds as IAllBondData[]).map(bond => {
                    return (
                      <Route exact key={bond.name} path={`/bonds/${bond.name}`}>
                        <Bond bond={bond} theme={theme} />
                      </Route>
                    );
                  })}
                <ChooseBond />
              </Route>
              <Route exact path="/">
                <Redirect to="/stake" />
              </Route>
              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </MuiThemeProvider>
    </ThemeProvider>
  );
}

export default App;
