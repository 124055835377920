import { BigNumber, BigNumberish, ethers } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20Abi } from "../abi/IERC20.json";
import { abi as sOHMv2 } from "../abi/sOhmv2.json";
import { abi as fuseProxy } from "../abi/FuseProxy.json";
import { abi as inviteABI } from "../abi/inviteABI.json";
import { abi as OlympusStakingABI } from "../abi/OlympusStakingv2.json";
import { abi as YPABI } from "../abi/YPABI.json";

import { setAll } from "../helpers";

import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAddressAsyncThunk, ICalcUserBondDetailsAsyncThunk } from "./interfaces";
import { FuseProxy, IERC20, SOhmv2, WsOHM, OlympusStakingv2 } from "src/typechain";

interface IUserBalances {
  balances: {
    ohm: string;
    sXPH: string;
    fsohm: string;
    wsohm: string;
    wsohmAsSohm: string;
    pool: string;
    // busd: string;
  };
}

export const getBalances = createAsyncThunk(
  "account/getBalances",
  async ({ address, networkID, provider }: IBaseAddressAsyncThunk) => {
    // const busdContract = new ethers.Contract(
    //   addresses[networkID].BUSD_ADDRESS as string,
    //   ierc20Abi,
    //   provider,
    // ) as IERC20;
    // const busdBalance = await busdContract.balanceOf(address);

    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20Abi, provider) as IERC20;
    // const XPHContract = new ethers.Contract(addresses[networkID].XPH_ADDRESS as string, ierc20Abi, provider) as IERC20;
    const YPcontract = new ethers.Contract(addresses[networkID].YP, YPABI, provider);

    const ohmBalance = await ohmContract.balanceOf(address);
    const ypBalance = await YPcontract.balanceOf(address);
    // console.log("bal", await provider.getBalance(address));
    const stakingContract = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      OlympusStakingABI,
      provider,
    );
    const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, provider) as SOhmv2;
    const stakeInfo = await stakingContract.stakeInfo(address);
    const sohmBalance = await sohmContract.balanceOf(address);
    const yieldAmount = Number(stakeInfo);

    let gonsBal, isLocked;
    // if (networkID == 56) {
    //   const stakingContract = new ethers.Contract(
    //     addresses[networkID].STAKING_ADDRESS as string,
    //     OlympusStakingABI,
    //     provider,
    //   ) as OlympusStakingv2;
    //   // const info = await stakingContract.warmupInfo("0x2ce09e11720d0F091ffcf3130DdCDe986719Ecf2");
    //   const info = await stakingContract.warmupInfo(address);
    //   gonsBal = await sohmContract.balanceForGons(info.gons);
    //   const epoch = await stakingContract.epoch();
    //   isLocked = Number(epoch.number) < Number(info.expiry);
    //   // console.log("epoch.number", epoch.number, "info.expiry", info.expiry, "isLocked", isLocked);
    // }
    return {
      balances: {
        ohm: ethers.utils.formatUnits(ohmBalance, "gwei"),
        sXPH: ethers.utils.formatUnits(sohmBalance, "gwei"),
        gonsBal: gonsBal && ethers.utils.formatUnits(gonsBal, "gwei"),
        busd: 0,
        usdt: 0,
        usdc: 0,
        wbnb: 0,
        bnb: 0,
        // xph: ethers.utils.formatUnits(xphBalance, "9"),
        // sxph: ethers.utils.formatUnits(sxphBalance, "9"),
        xphV2: 0,
      },
      yieldAmount,
      isLocked,
      ohmAllowanceInRouter: 0,
      busdAllowanceInRouter: 0,
      usdtAllowanceInRouter: 0,
      usdcAllowanceInRouter: 0,
      wbnbAllowanceInRouter: 0,
      ohmAllowanceInMigration: 0,
      sohmAllowanceInMigration: 0,
      xphV2AllowanceInRouter: 0,
      ypBalance: ethers.utils.formatUnits(ypBalance, 9),
      // xphAllowanceInRouter: ethers.utils.formatUnits(xphAllowanceInRouter, "9"),
      // sxphAllowanceInRouter: ethers.utils.formatUnits(sxphAllowanceInRouter, "9"),
    };
  },
);

interface IUserAccountDetails {
  balances: {
    dai: string;
    ohm: string;
    sXPH: string;
  };
  staking: {
    ohmStake: number;
    ohmUnstake: number;
  };
  wrapping: {
    sohmWrap: number;
    wsohmUnwrap: number;
  };
  bonding: {
    daiAllowance: number;
  };
}

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ networkID, provider, address }: IBaseAddressAsyncThunk, { dispatch }) => {
    // try {
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20Abi, provider);
    const stakeAllowance = await ohmContract.allowance(address, addresses[networkID].STAKING_HELPER_ADDRESS);
    // const stakeAllowance=0
    const inviteContract = new ethers.Contract(addresses[networkID].invite as string, inviteABI, provider);
    const superiorAddress = await inviteContract.superiorAddress(address);
    const isActivecode = superiorAddress != "0x0000000000000000000000000000000000000000";
    const sohmContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, sOHMv2, provider) as SOhmv2;
    let gonsBal: any;
    const stakingContract = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      OlympusStakingABI,
      provider,
    );

    try {
      const info = await stakingContract.warmupInfo(address);
      gonsBal = await sohmContract.balanceForGons(info.gons);
    } catch (error) {
      console.log("error", error);
    }

    const unstakeAllowance = await sohmContract.allowance(address, addresses[networkID].STAKING_ADDRESS);

    await dispatch(getBalances({ address, networkID, provider }));

    return {
      staking: {
        ohmStake: +stakeAllowance,
        ohmUnstake: +unstakeAllowance,
      },
      isActivecode,
      // wrapping: {
      //   ohmWrap: +wrapAllowance,
      // ohmUnwrap: +unwrapAllowance,
      // },
      // pooling: {
      //   sohmPool: +poolAllowance,
      // },
    };
  },
);

export interface IUserBondDetails {
  bondDetails?: any;
  allowance: number[];
  interestDue: number;
  bondMaturationBlock: number;
  pendingPayout: string; //Payout formatted in gwei.
}
export const calculateUserBondDetails = createAsyncThunk(
  "account/calculateUserBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetailsAsyncThunk) => {
    if (!address) {
      return {
        bond: "",
        displayName: "",
        bondIconSvg: "",
        isLP: false,
        allowance: [0, 0],
        balance: ["0", "0"],
        interestDue: 0,
        bondMaturationBlock: 0,
        pendingPayout: "",
        bondDetails: [],
      };
    }

    // dispatch(fetchBondInProgress());
    // const signer = provider.getSigner();
    // Calculate bond details.

    const bondContract = bond.getContractForBond(networkID, provider);

    const reserveContract = bond.getContractForReserve(networkID, provider);
    const busdContract = new ethers.Contract(addresses[networkID].USDT as string, ierc20Abi, provider) as IERC20;

    let interestDue = 0;
    let pendingPayout = 0;
    let bondMaturationBlock = 0;

    const bondDetails = await bondContract.userData(address);
    if (bondDetails?.length > 0) {
      interestDue = bondDetails[0].payout / Math.pow(10, 9);
      bondMaturationBlock = +bondDetails[0].vesting + +bondDetails[0].lastBlock;
      pendingPayout = await bondContract.pendingPayoutFor(address, 0);
    }

    let allowance,
      balance = BigNumber.from(0);
    allowance = await reserveContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
    balance = await reserveContract.balanceOf(address);
    const busdBalance = await busdContract.balanceOf(address);
    const busdAllowance = await busdContract.allowance(address, bond.getAddressForBondHelper(networkID) as string);
    // formatEthers takes BigNumber => String
    const balanceVal = ethers.utils.formatEther(balance);
    // balanceVal should NOT be converted to a number. it loses decimal precision

    return {
      bond: bond.name,
      displayName: bond.displayName,
      bondIconSvg: bond.bondIconSvg,
      isLP: bond.isLP,
      allowance: [Number(busdAllowance.toString()), Number(allowance.toString())],
      balance: [ethers.utils.formatEther(busdBalance), balanceVal],
      bondDetails,
      interestDue,
      bondMaturationBlock,
      pendingPayout: ethers.utils.formatUnits(pendingPayout, "gwei"),
    };
  },
);

interface IAccountSlice {
  bonds: { [key: string]: IUserBondDetails };
  balances: {
    ohm: string;
    sXPH: string;
    dai: string;
    oldsohm: string;
    fsohm: string;
    wsohm: string;
    wsohmAsSohm: string;
    pool: string;
    busd: string;
    gonsBal: string;
    usdt: string;
    usdc: string;
    wbnb: string;
    bnb: string;
    // xph: string;
    // sxph: string;
    xphV2: string;
  };
  yieldAmount: number;
  loading: boolean;
  staking: {
    ohmStake: number;
    ohmUnstake: number;
  };
  pooling: {
    sohmPool: number;
  };
  isLocked: boolean;
  ohmAllowanceInRouter: string;
  xphV2AllowanceInRouter: string;
  busdAllowanceInRouter: string;
  usdtAllowanceInRouter: string;
  usdcAllowanceInRouter: string;
  wbnbAllowanceInRouter: string;
  ohmAllowanceInMigration: string;
  sohmAllowanceInMigration: string;
  isActivecode: boolean;
  userClaimData: any[];
  daoPending: string;
  rewardPending: string;
  stakePending: string;
  isStakeClaimApproved: boolean;
  ypBalance: number;
}

const initialState: IAccountSlice = {
  isActivecode: false,
  loading: false,
  bonds: {},
  balances: {
    ohm: "",
    sXPH: "",
    dai: "",
    oldsohm: "",
    fsohm: "",
    wsohm: "",
    pool: "",
    wsohmAsSohm: "",
    busd: "",
    usdt: "",
    usdc: "",
    wbnb: "",
    bnb: "",
    gonsBal: "",
    // xph: "",
    // sxph: "",
    xphV2: "",
  },
  userClaimData: [],
  daoPending: "0",
  rewardPending: "0",
  stakePending: "0",
  isStakeClaimApproved: false,
  ypBalance: 0,
  yieldAmount: 0,
  staking: { ohmStake: 0, ohmUnstake: 0 },
  // wrapping: { sohmWrap: 0, wsohmUnwrap: 0 },
  pooling: { sohmPool: 0 },
  isLocked: false,
  ohmAllowanceInRouter: "",
  busdAllowanceInRouter: "",
  usdtAllowanceInRouter: "",
  usdcAllowanceInRouter: "",
  wbnbAllowanceInRouter: "",
  ohmAllowanceInMigration: "",
  sohmAllowanceInMigration: "",
  xphV2AllowanceInRouter: "",
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getBalances.pending, state => {
        state.loading = true;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(calculateUserBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calculateUserBondDetails.fulfilled, (state, action) => {
        if (!action.payload) return;
        const bond = action.payload.bond;
        state.bonds[bond] = action.payload;
        state.loading = false;
      })
      .addCase(calculateUserBondDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
