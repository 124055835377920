import { ethers, BigNumberish } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20ABI } from "../abi/IERC20.json";
import { abi as OlympusStakingv2ABI } from "../abi/OlympusStakingv2.json";
import { abi as FuseProxyABI } from "../abi/FuseProxy.json";
import { abi as DistributorContractABI } from "../abi/Distributor.json";
import { abi as sOHMv2 } from "../abi/sOhmv2.json";
import { setAll, getTokenPrice, getMarketPrice } from "../helpers";
import apollo from "../lib/apolloClient";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "src/store";
import { IBaseAsyncThunk } from "./interfaces";
import { OlympusStakingv2, SOhmv2, IERC20, FuseProxy, DistributorContract } from "../typechain";

interface IProtocolMetrics {
  readonly timestamp: string;
  readonly ohmCirculatingSupply: string;
  readonly sOhmCirculatingSupply: string;
  readonly totalSupply: string;
  readonly ohmPrice: string;
  readonly marketCap: string;
  readonly totalValueLocked: string;
  readonly treasuryMarketValue: string;
  readonly nextEpochRebase: string;
  readonly nextDistributedOhm: string;
}

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch }) => {
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, provider);
    const stakingContract = new ethers.Contract(
      addresses[networkID].STAKING_ADDRESS as string,
      OlympusStakingv2ABI,
      provider,
    ) as OlympusStakingv2;

    const treasuryContract = new ethers.Contract(
      addresses[networkID].TREASURY_ADDRESS as string,
      FuseProxyABI,
      provider,
    ) as FuseProxy;

    const stakingDistributorContract = new ethers.Contract(
      addresses[networkID].DISTRIBUTOR_ADDRESS as string,
      DistributorContractABI,
      provider,
    ) as any;
    const sohmMainContract = new ethers.Contract(
      addresses[networkID].SOHM_ADDRESS as string,
      sOHMv2,
      provider,
    ) as SOhmv2;

    // const totalSupply = Number(await ohmContract.totalSupply());
    // const StakeBalance = Number(await ohmContract.balanceOf(addresses[networkID].STAKING_ADDRESS));
    // const daoBal = Number(await ohmContract.balanceOf(addresses[networkID].DAOaddr));

    // const Price = await getMarketPrice({ networkID, provider });
    // const currentBlock = await provider.getBlockNumber();

    // const stakingTVL = (StakeBalance * Price) / Math.pow(10, 9);
    // // NOTE (appleseed): marketPrice from Graph was delayed, so get CoinGecko price
    // // const marketPrice = parseFloat(graphData.data.protocolMetrics[0].ohmPrice);
    // let marketPrice = Price;
    // // try {
    // //   const originalPromiseResult = await dispatch(
    // //     loadMarketPrice({ networkID: networkID, provider: provider }),
    // //   ).unwrap();
    // //   marketPrice = originalPromiseResult?.marketPrice;
    // // } catch (rejectedValueOrSerializedError) {
    // //   // handle error here
    // //   console.error("Returned a null response from dispatch(loadMarketPrice)");
    // //   return;
    // // }
    // // console.log(new Date(), Price, marketPrice);

    // // console.log(ethers.utils.parseUnits("10000"), "10000");
    // const marketCap = (marketPrice * totalSupply) / Math.pow(10, 9);
    // const circSupply = 0;
    // const treasuryMarketValue = 0;
    // // const currentBlock = parseFloat(graphData.data._meta.block.number);

    // const totalReserves = await treasuryContract.totalReserves();
    // const rate = await stakingDistributorContract.info(0);
    // const epoch = await stakingContract.epoch();
    // const circ = await sohmMainContract.circulatingSupply();
    // const currentIndex = await stakingContract.index();

    const [totalReserves, rate, epoch, circ, currentIndex, totalSupply, StakeBalance, daoBal, Price, currentBlock] =
      await Promise.all([
        treasuryContract.totalReserves(),
        stakingDistributorContract.info(0),
        stakingContract.epoch(),
        sohmMainContract.circulatingSupply(),
        stakingContract.index(),
        ohmContract.totalSupply(),
        ohmContract.balanceOf(addresses[networkID].STAKING_ADDRESS),
        ohmContract.balanceOf(addresses[networkID].DAOaddr),
        getMarketPrice({ networkID, provider }),
        provider.getBlockNumber(),
      ]);

    const stakingTVL = (Number(StakeBalance) * Price) / Math.pow(10, 9);
    let marketPrice = Price;
    const marketCap = (marketPrice * Number(totalSupply)) / Math.pow(10, 9);
    const circSupply = 0;
    const treasuryMarketValue = 0;
    const endBlock = epoch.endBlock;
    const StakeRate = rate[0];
    const stakingReward = epoch.distribute;
    const stakingRebase = Number(stakingReward.toString()) / Number(circ.toString());
    const fiveDayRate = Math.pow(1 + stakingRebase, 5 * 3) - 1;
    const stakingAPY = Math.pow(1 + stakingRebase, 365 * 3) - 1;

    // Math.LOG10E;
    // const data = Math.LN10(a1);
    // console.log("data", data);
    // const runWay = Math.log(treasuryReBalance / StakeBalance) / Math.log(1 + StakeRate / Math.pow(10, 6)) / 3;

    return {
      endBlock,
      currentIndex: ethers.utils.formatUnits(currentIndex, "gwei"),
      totalReserves: ethers.utils.formatUnits(totalReserves, "9"),
      currentBlock,
      fiveDayRate,
      stakingAPY,
      stakingTVL,
      stakingRebase,
      marketCap,
      marketPrice,
      circSupply,
      totalSupply: Number(totalSupply) / Math.pow(10, 9),
      treasuryMarketValue,
      StakeRate,
      daoBal: Number(daoBal),
    } as unknown as IAppData;
  },
);

/**
 * checks if app.slice has marketPrice already
 * if yes then simply load that state
 * if no then fetches via `loadMarketPrice`
 *
 * `usage`:
 * ```
 * const originalPromiseResult = await dispatch(
 *    findOrLoadMarketPrice({ networkID: networkID, provider: provider }),
 *  ).unwrap();
 * originalPromiseResult?.whateverValue;
 * ```
 */
export const findOrLoadMarketPrice = createAsyncThunk(
  "app/findOrLoadMarketPrice",
  async ({ networkID, provider }: IBaseAsyncThunk, { dispatch, getState }) => {
    const state: any = getState();
    let marketPrice;
    // check if we already have loaded market price
    if (state.app.loadingMarketPrice === false && state.app.marketPrice) {
      // go get marketPrice from app.state
      marketPrice = state.app.marketPrice;
    } else {
      // we don't have marketPrice in app.state, so go get it
      try {
        const originalPromiseResult = await dispatch(
          loadMarketPrice({ networkID: networkID, provider: provider }),
        ).unwrap();
        marketPrice = originalPromiseResult?.marketPrice;
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error("Returned a null response from dispatch(loadMarketPrice)");
        return;
      }
    }
    return { marketPrice };
  },
);

/**
 * - fetches the XPH price from CoinGecko (via getTokenPrice)
 * - falls back to fetch marketPrice from ohm-dai contract
 * - updates the App.slice when it runs
 */
const loadMarketPrice = createAsyncThunk("app/loadMarketPrice", async ({ networkID, provider }: IBaseAsyncThunk) => {
  let marketPrice: number;
  try {
    marketPrice = await getMarketPrice({ networkID, provider });
    marketPrice = marketPrice;
  } catch (e) {
    marketPrice = 0;
  }
  // console.log("marketPrice", marketPrice);

  return { marketPrice };
});

interface IAppData {
  readonly circSupply?: number;
  readonly currentIndex?: string;
  readonly currentBlock?: number;
  readonly fiveDayRate?: number;
  readonly loading: boolean;
  readonly loadingMarketPrice: boolean;
  readonly marketCap?: number;
  readonly marketPrice?: number;
  readonly stakingAPY?: number;
  readonly stakingRebase?: number;
  readonly stakingTVL?: number;
  readonly totalSupply?: number;
  readonly treasuryBalance?: number;
  readonly treasuryMarketValue?: number;
  readonly endBlock1?: number;
  readonly daoBal?: number;

  readonly StakeRate?: number;
  readonly totalReserves?: number;
}

const initialState: IAppData = {
  loading: false,
  loadingMarketPrice: false,
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.error(error.name, error.message, error.stack);
      })
      .addCase(loadMarketPrice.pending, (state, action) => {
        state.loadingMarketPrice = true;
      })
      .addCase(loadMarketPrice.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loadingMarketPrice = false;
      })
      .addCase(loadMarketPrice.rejected, (state, { error }) => {
        state.loadingMarketPrice = false;
        console.error(error.name, error.message, error.stack);
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
