// import { createTheme } from "@mui/lab";
import type {} from "@mui/lab/themeAugmentation";
import { createTheme } from "@mui/material/styles";
const theme = createTheme({
  components: {
    MuiLoadingButton: {
      styleOverrides: {
        root: {
          // color: "red",
          fontFamily: "SF Pro Display",
          fontWeight: "500",
          background: "#93AEBC",
          borderRadius: "5px",
          fontSize: "1rem",
          color: "#fff !important",
          border: "none",
          textTransform: "initial",
          boxShadow: "none",
          // maxHeight: "48px",
          "&.loadingIndicator": {
            position: "relative !important",
            // right:''
            marginLeft: "10px",
          },
          "&.Mui-disabled": {
            // backgroundColor: "transparent",
            // color: "#FCFCFC !important",
            // color: "rgba(0, 0, 0, 0.26) !important",
            color: "rgba(0, 0, 0, 0.26) !important",
            // backgroundColor: "rgba(0, 0, 0, 0.12)",
            background: "rgba(0, 0, 0, 0.12)",
            // border: "1px solid rgba(255, 255, 255, 0.12) ",
          },
          "&.outlinedPrimary": {
            color: "#93AEBC !important",
            border: "1px solid rgba(37, 52, 73, 0.5)",
            height: "33px",
            padding: "9px 20px",
            borderColor: "#93AEBC",
          },
          "&:hover": {
            background: "#93AEBC",
            // border: "none",
            boxShadow: "none",
            color: "#F8CC82 !important",
          },
          "&.outlined-btn": {
            color: "#93AEBC !important",
            border: "1px solid #93AEBC",
            backgroundColor: "transparent",
            width: "100%",
            "&:hover": {
              color: "#fff !important",
              backgroundColor: "#FA22CB",
            },
            "&.Mui-disabled": {
              // width: "100% !important",
              opacity: "0.5 !important",

              // color: "rgba(255, 255, 255, 0.3) !important",
              // border: "1px solid rgba(255, 255, 255, 0.12) !important",
            },
          },
          "&.investment-btn": {
            "&.Mui-disabled": {
              opacity: "0.5",
            },
          },
          // outlinedPrimary: {
          // },
        },

        loadingIndicator: {
          root: {
            // loadingPosition: "end",
            position: "relative !important",
          },
          style: {
            position: "relative !important",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontFamily: "Square",
        },
        h1: {
          fontSize: "3.3rem",
        },
        h2: {
          fontSize: "2.3rem",
          fontWeight: 600,
          letterSpacing: "1.3px",
        },
        h3: {
          fontSize: "1.75rem",
        },
        h4: {
          fontSize: "1.5rem",
        },
        h5: {
          fontSize: "1.25rem",
          letterSpacing: "0.4px",
        },
        h6: {
          fontSize: "1rem",
        },
        body1: {
          fontSize: "0.875rem",
          fontWeight: 500,
          lineHeight: 1,
        },
        body2: {
          fontSize: "0.75rem",
          fontWeight: 400,
          lineHeight: 1,
        },
      },
    },
  },
});

export default theme;
