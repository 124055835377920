import { useEffect, useState } from "react";
import { Box, Button, Typography, Popper, Paper, Divider, SvgIcon, Slide, Drawer } from "@material-ui/core";
import { ReactComponent as ArrowUpIcon } from "../../assets/icons/arrow-up.svg";
import { ReactComponent as CaretDownIcon } from "../../assets/icons/caret-down.svg";
import { useWeb3Context } from "src/hooks/web3Context";
import { shorten, trim } from "src/helpers";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { ReactComponent as ShareIcon } from "../../assets/icons/share.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { ReactComponent as BNBIcon } from "src/assets/images/logo.svg";
import { ReactComponent as DexscreenerIcon } from "src/assets/icons/dexscreener.svg";
import { ReactComponent as PancakeSwapIcon } from "src/assets/icons/pancake_swap.svg";
import TokenIcon from "src/assets/images/logo.svg";
import TokenDRIcon from "src/assets/images/token_dr.png";
import TokenYPIcon from "src/assets/images/token_yp.png";
import { addresses, TOKEN_DECIMALS } from "src/constants";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { success } from "src/slices/MessagesSlice";
import { t, Trans } from "@lingui/macro";
import { useAppSelector } from "src/hooks";
import { Skeleton } from "@material-ui/lab";

function ConnectMenu({ theme }) {
  const dispatch = useDispatch();

  const { connect, disconnect, connected, web3, chainID, address } = useWeb3Context();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isConnected, setConnected] = useState(connected);
  const [isHovering, setIsHovering] = useState(false);
  const networkID = chainID;
  const YPAddress = addresses[networkID].YP;
  const OHM_ADDRESS = addresses[networkID].OHM_ADDRESS;
  const SOHM_ADDRESS = addresses[networkID].SOHM_ADDRESS;
  const marketPrice = useAppSelector(state => {
    return state.app.marketPrice;
  });
  const ohmBalance = useAppSelector(state => {
    return state.account.balances.ohm;
  });
  const ypBalance = useAppSelector(state => {
    return state.account.ypBalance;
  });
  const sohmBalance = useAppSelector(state => {
    return state.account.balances && state.account.balances.sXPH;
  });
  const isAppLoading = useAppSelector(state => state.app.loading);

  const addTokenToWallet = async (tokenSymbol, tokenAddress) => {
    if (window.ethereum) {
      const host = window.location.origin;
      let tokenDecimals = TOKEN_DECIMALS;
      let tokenPath = TokenDRIcon;
      switch (tokenSymbol) {
        case "DR":
          tokenPath = TokenDRIcon;
          break;
        case "sDR":
          tokenPath = TokenDRIcon;
          break;

        default:
          tokenPath = TokenYPIcon;
      }
      // const imageURL = `${host}/${tokenPath}`;
      try {
        await window.ethereum.request({
          method: "wallet_watchAsset",
          params: {
            type: "ERC20",
            options: {
              address: tokenAddress,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image: tokenPath,
            },
          },
        });
        setAnchorEl(null);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  let buttonText = <Trans>Connect Wallet</Trans>;
  let clickFunc = connect;

  const handleClick = event => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  if (isConnected) {
    buttonText = <span>{shorten(address)}</span>;
    // clickFunc = () => {
    //   console.log("click");
    // };
    clickFunc = handleClick;
  }

  if (pendingTransactions && pendingTransactions.length > 0) {
    buttonText = <Trans>In progress</Trans>;
    clickFunc = handleClick;
  }

  const open = Boolean(anchorEl);
  const id = open ? "ohm-popper-pending" : undefined;

  const primaryColor = theme === "light" ? "#49A1F2" : "#F8CC82";
  const buttonStyles =
    "pending-txn-container" + (isHovering && pendingTransactions.length > 0 ? " hovered-button" : "");

  const getEtherscanUrl = txnHash => {
    return chainID === 56 ? "https://bscscan.com/tx/" + txnHash : "https://testnet.bscscan.com/tx/" + txnHash;
  };

  // useEffect(() => {
  //   if (pendingTransactions.length === 0) {
  //     setAnchorEl(null);
  //   }
  // }, [pendingTransactions]);

  useEffect(() => {
    setConnected(connected);
  }, [web3, connected]);

  const copyLink = () => {
    // console.log("123copy");
    dispatch(success(t`Copy successful!`));
    // setInvitationOpen(false);
    setAnchorEl(null);
  };
  return (
    <div
      onMouseEnter={e => (pendingTransactions && pendingTransactions.length > 0 ? handleClick(e) : null)}
      onMouseLeave={e => (pendingTransactions && pendingTransactions.length > 0 ? handleClick(e) : null)}
      className="wallet-menu"
      id="wallet-menu"
    >
      <Button
        id="wallet-button"
        className={buttonStyles}
        variant="contained"
        color="secondary"
        size="large"
        // style={pendingTransactions.length > 0 ? { color: primaryColor } : {}}
        onClick={clickFunc}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        key={1}
      >
        {buttonText}
        {pendingTransactions.length > 0 && (
          <Slide direction="left" in={isHovering} {...{ timeout: 333 }}>
            <SvgIcon className="caret-down" component={CaretDownIcon} />
          </Slide>
        )}
      </Button>
      <Drawer anchor={"right"} open={open} onClose={handleClick} className="right-drawer">
        <Box className="right-drawer-close" display="flex" justifyContent="flex-end" alignItems="center">
          <Box className="close-box" onClick={handleClick}>
            <SvgIcon component={XIcon} />
          </Box>
        </Box>
        <Box className="token-box-container">
          <Box
            onClick={() =>
              window.open(`https://www.dextools.io/app/bsc/pair-explorer/0x62eA391404325e003641FF15f8dC792358C0e33D`)
            }
            className="token-box"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <SvgIcon
                color="primary"
                component={DexscreenerIcon}
                style={{ width: "28px", height: "34px", marginRight: "15px" }}
                viewBox="0 0 28 34"
              />
              <Box>
                <Typography variant="body2" style={{ marginBottom: "8px" }}>
                  <Trans>Get on</Trans>
                </Typography>
                <Typography variant="body1">
                  <Trans>Dexscreener</Trans>
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box style={{ marginLeft: "20px" }}>
                <SvgIcon component={ShareIcon} viewBox="0 0 10 10" style={{ width: "10px", height: "10px" }} />
              </Box>
            </Box>
          </Box>
          <Box
            onClick={() =>
              window.open(
                `https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0x22be38375124aa1ada0c25b515ad5744a59b0885`,
              )
            }
            className="token-box"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              <SvgIcon
                color="primary"
                component={PancakeSwapIcon}
                style={{ width: "28px", height: "34px", marginRight: "15px" }}
                viewBox="0 0 28 34"
              />
              <Box>
                <Typography variant="body2" style={{ marginBottom: "8px" }}>
                  <Trans>Get on</Trans>
                </Typography>
                <Typography variant="body1">
                  <Trans>PancakeSwap</Trans>
                </Typography>
              </Box>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="center">
              <Box style={{ marginLeft: "20px" }}>
                <SvgIcon component={ShareIcon} viewBox="0 0 10 10" style={{ width: "10px", height: "10px" }} />
              </Box>
            </Box>
          </Box>
          <Box className="token-box" display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" justifyContent="center">
              {/* <SvgIcon
                color="primary"
                component={BNBIcon}
                style={{ width: "22px", height: "22px", marginRight: "10px" }}
                viewBox="0 0 22 22"
              /> */}
              <img src={TokenDRIcon} alt="DR" style={{ width: "22px", height: "22px", marginRight: "10px" }} />

              <Typography variant="body1" style={{ height: "22px", lineHeight: "22px" }}>
                DR
              </Typography>
            </Box>
            <Box display="flex">
              <Box>
                <Typography align="right" variant="body1" style={{ marginBottom: "4px" }}>
                  {isAppLoading ? <Skeleton width="80px" /> : <>{trim(Number(ohmBalance), 4)}</>}
                </Typography>
                <Typography align="right" variant="body2">
                  {isAppLoading ? <Skeleton width="80px" /> : <>{trim(Number(ohmBalance * marketPrice), 4)}</>}
                </Typography>
              </Box>
              <Box style={{ marginLeft: "20px" }}>
                <SvgIcon component={ShareIcon} viewBox="0 0 10 10" style={{ width: "10px", height: "10px" }} />
              </Box>
            </Box>
          </Box>
          <Box className="token-box" display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" justifyContent="center">
              {/* <SvgIcon
                color="primary"
                component={BNBIcon}
                style={{ width: "22px", height: "22px", marginRight: "10px" }}
                viewBox="0 0 22 22"
              /> */}
              <img src={TokenDRIcon} alt="DR" style={{ width: "22px", height: "22px", marginRight: "10px" }} />

              <Typography variant="body1" style={{ height: "22px", lineHeight: "22px" }}>
                sDR
              </Typography>
            </Box>
            <Box display="flex">
              <Box>
                <Typography align="right" variant="body1" style={{ marginBottom: "4px" }}>
                  {isAppLoading ? <Skeleton width="80px" /> : <>{trim(Number(sohmBalance), 4)}</>}
                </Typography>
                <Typography align="right" variant="body2">
                  {isAppLoading ? <Skeleton width="80px" /> : <>{trim(Number(sohmBalance * marketPrice), 4)}</>}
                </Typography>
              </Box>
              <Box style={{ marginLeft: "20px" }}>
                <SvgIcon component={ShareIcon} viewBox="0 0 10 10" style={{ width: "10px", height: "10px" }} />
              </Box>
            </Box>
          </Box>
          <Box className="token-box" display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" justifyContent="center">
              {/* <SvgIcon
                color="primary"
                component={BNBIcon}
                style={{ width: "22px", height: "22px", marginRight: "10px" }}
                viewBox="0 0 22 22"
              /> */}
              <img src={TokenYPIcon} alt="YP" style={{ width: "22px", height: "22px", marginRight: "10px" }} />

              <Typography variant="body1" style={{ height: "22px", lineHeight: "22px" }}>
                YP
              </Typography>
            </Box>
            <Box display="flex">
              <Box>
                <Typography align="right" variant="body1" style={{ marginBottom: "4px" }}>
                  {isAppLoading ? <Skeleton width="80px" /> : <>{trim(Number(ypBalance), 4)}</>}
                </Typography>
                <Typography align="right" variant="body2">
                  {/* {isAppLoading ? <Skeleton width="80px" /> : <>{trim(Number(sohmBalance * marketPrice), 4)}</>} */}
                </Typography>
              </Box>
              <Box style={{ marginLeft: "20px" }}>
                <SvgIcon component={ShareIcon} viewBox="0 0 10 10" style={{ width: "10px", height: "10px" }} />
              </Box>
            </Box>
          </Box>
        </Box>
        {/* add */}
        <Box className="add-token-container">
          <Box
            className="add-token-box"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => addTokenToWallet("DR", OHM_ADDRESS)}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              {/* <SvgIcon
                color="primary"
                component={BNBIcon}
                style={{ width: "22px", height: "22px", marginRight: "10px" }}
                viewBox="0 0 22 22"
              /> */}
              <img src={TokenDRIcon} alt="DR" style={{ width: "22px", height: "22px", marginRight: "10px" }} />
              <Typography variant="body1" style={{ height: "22px", lineHeight: "22px" }}>
                DR
              </Typography>
            </Box>
            <Button type="text">
              <Typography variant="body1">Add</Typography>
            </Button>
          </Box>
          <Box
            className="add-token-box"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => addTokenToWallet("sDR", SOHM_ADDRESS)}
          >
            <Box display="flex" lignItems="center" justifyContent="center">
              {/* <SvgIcon
                color="primary"
                component={BNBIcon}
                style={{ width: "22px", height: "22px", marginRight: "10px" }}
                viewBox="0 0 22 22"
              /> */}
              <img src={TokenDRIcon} alt="sDR" style={{ width: "22px", height: "22px", marginRight: "10px" }} />

              <Typography variant="body1" style={{ height: "22px", lineHeight: "22px" }}>
                sDR
              </Typography>
            </Box>
            <Button type="text">
              <Typography variant="body1">Add</Typography>
            </Button>
          </Box>
          <Box
            className="add-token-box"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            onClick={() => addTokenToWallet("YP", YPAddress)}
          >
            <Box display="flex" alignItems="center" justifyContent="center">
              {/* <SvgIcon
                color="primary"
                component={BNBIcon}
                style={{ width: "22px", height: "22px", marginRight: "10px" }}
                viewBox="0 0 22 22"
              /> */}
              <img src={TokenYPIcon} alt="YP" style={{ width: "22px", height: "22px", marginRight: "10px" }} />

              <Typography variant="body1" style={{ height: "22px", lineHeight: "22px" }}>
                YP
              </Typography>
            </Box>
            <Button type="text">
              <Typography variant="body1">Add</Typography>
            </Button>
          </Box>
        </Box>

        {/* invite */}
        <Box className="invite-container">
          <Box display="flex" alignItems="center" justifyContent="space-between" className="invite-title">
            <Typography variant="body1">
              <Trans>Invitation link:</Trans>
            </Typography>
            <CopyToClipboard text={`${window.location.origin}/stake?invite=${address}`}>
              <Box style={{ cursor: "pointer" }} onClick={copyLink}>
                <SvgIcon component={CopyIcon} viewBox="0 0 18 18" />
              </Box>
            </CopyToClipboard>
          </Box>
          <Box className="invite-link">
            <Typography variant="body2">{`${window.location.origin}/stake?invite=${address}`}</Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" style={{ marginBottom: "20px" }}>
          <Button type="text" onClick={disconnect}>
            <Typography variant="body1">
              <Trans>Disconnect</Trans>
            </Typography>
          </Button>
        </Box>
      </Drawer>
      {/* <Popper id={id} open={open} anchorEl={anchorEl} placement="bottom-end" transition>
        {({ TransitionProps }) => {
          return (
            <Fade {...TransitionProps} timeout={100}>
              <Paper className="ohm-menu" elevation={1}>
                {pendingTransactions.map((x, i) => (
                  <Box key={i} fullWidth>
                    <Link key={x.txnHash} href={getEtherscanUrl(x.txnHash)} target="_blank" rel="noreferrer">
                      <Button size="large" variant="contained" color="secondary" fullWidth>
                        <Typography align="left">
                          {x.text} <SvgIcon component={ArrowUpIcon} />
                        </Typography>
                      </Button>
                    </Link>
                  </Box>
                ))}
                <Box className="add-tokens">
                  <Divider color="secondary" />
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    onClick={disconnect}
                    style={{ marginBottom: "0px" }}
                    fullWidth
                  >
                    <Typography>
                      <Trans>Disconnect</Trans>
                    </Typography>
                  </Button>
                </Box>
              </Paper>
            </Fade>
          );
        }}
      </Popper> */}
    </div>
  );
}

export default ConnectMenu;
