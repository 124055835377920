import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20ABI } from "../abi/IERC20.json";
import { abi as WBNBABI } from "../abi/WBNB.json";
import { abi as PreSaleABI } from "../abi/PreSale.json";
import { abi as ExchangeABI } from "../abi/AxphMirgation.json";
import { abi as RouterABI } from "../abi/RouterContract.json";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { error, info } from "./MessagesSlice";
import { IERC20, OlympusStakingv2, StakingHelper } from "src/typechain";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError } from "./interfaces";
import { setAll, formatMoney } from "../helpers";
import { RootState } from "src/store";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";
import { getBalances } from "./AccountSlice";

export const approveSwap = createAsyncThunk(
  "ido/approveSwap",
  async ({ provider, address, networkID, type, topToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider.getSigner();
    const busdContract = new ethers.Contract(addresses[networkID].DAI_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
    // const ohmContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const WBNBContract = new ethers.Contract(addresses[networkID].WBNB_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const USDTContract = new ethers.Contract(addresses[networkID].USDT_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const USDCContract = new ethers.Contract(addresses[networkID].USDC_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const xphV2Contract = new ethers.Contract(
      addresses[networkID].XPHV2_ADDRESS as string,
      ierc20ABI,
      signer,
    ) as IERC20;
    // const approveAllowance = await busdContract.allowance(address, addresses[networkID].PreSale_ADDRESS);
    let approveTx;
    try {
      if (topToken == "BUSD") {
        approveTx = await busdContract.approve(
          addresses[networkID].ROUTER_ADDRESS,
          // totalSupply,
          // ethers.utils.parseUnits(String(2000)).toString(),
          ethers.utils.parseUnits("1000000000").toString(),
        );
      } else if (topToken == "XPH") {
        approveTx = await ohmContract.approve(
          addresses[networkID].ROUTER_ADDRESS,
          ethers.utils.parseUnits("1000000000", "9").toString(),
        );
      } else if (topToken == "XPHV2") {
        approveTx = await xphV2Contract.approve(
          addresses[networkID].ROUTER_ADDRESS,
          ethers.utils.parseUnits("1000000000", "9").toString(),
        );
      } else if (topToken == "WBNB") {
        approveTx = await WBNBContract.approve(
          addresses[networkID].ROUTER_ADDRESS,
          ethers.utils.parseUnits("1000000000").toString(),
        );
      } else if (topToken == "USDT") {
        approveTx = await USDTContract.approve(
          addresses[networkID].ROUTER_ADDRESS,
          ethers.utils.parseUnits("1000000000").toString(),
        );
      } else if (topToken == "USDC") {
        approveTx = await USDCContract.approve(
          addresses[networkID].ROUTER_ADDRESS,
          ethers.utils.parseUnits("1000000000").toString(),
        );
      }
      // console.log("approveTx", approveTx);
      const text = "Approve";
      const pendingTxnType = "approve_swap";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        return;
        // return {
        //   busdAllowance: "2000",
        // };
      }
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  },
);

export const getAllowanceBUSD = createAsyncThunk(
  "ido/getAllowanceBUSD",
  async ({ provider, address, networkID }: any, { dispatch }) => {
    const signer = provider.getSigner();
    const busdContract = new ethers.Contract(addresses[networkID].BUSD_ADDRESS as string, ierc20ABI, signer) as IERC20;

    const approveAllowance = await busdContract.allowance(address, addresses[networkID].PreSale_ADDRESS);
    const presaleContract = new ethers.Contract(
      addresses[networkID].PreSale_ADDRESS as string,
      PreSaleABI,
      signer,
    ) as any;
    const isWhiteeListed = await presaleContract.whiteListed(address);
    let _sellAmount = await presaleContract?.saleAmount();
    _sellAmount = _sellAmount && ethers.utils.formatUnits(_sellAmount);
    let _toTalAmount = await presaleContract?.totalAmount();

    _toTalAmount = _toTalAmount && ethers.utils.formatUnits(_toTalAmount);
    // setTotalAmount(formatMoney(Number(_toTalAmount / 5) || 0));
    const totalAmount = formatMoney(Number(_toTalAmount / 5) || 0);
    const remaining = formatMoney(Number((_toTalAmount - _sellAmount) / 5) || 0);
    const totalSubscription = formatMoney(Number(_sellAmount) || 0);
    // setTotalSubscription(_totalSubscription);
    // setRemaining(_remaining);

    return {
      busdAllowance: ethers.utils.formatEther(String(approveAllowance)),
      isWhiteeListed,
      totalAmount,
      remaining,
      totalSubscription,
    };
  },
);

export const approveExchange = createAsyncThunk(
  "ido/approveExchange",
  async ({ provider, address, networkID }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider.getSigner();
    const aXPHContract = new ethers.Contract(addresses[networkID].AXPH_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const aXPHBalance = await aXPHContract.balanceOf(address);
    let approveTx;
    try {
      approveTx = await aXPHContract.approve(addresses[networkID].Ido_EXCHANGE_ADDRESS, aXPHBalance);
      // console.log("approveTx", approveTx);
      const text = "Approve";
      const pendingTxnType = "approve_exchange";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        return;
      }
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  },
);

export const getExchangeBalance = createAsyncThunk(
  "ido/getExchangeBalance",
  async ({ provider, address, networkID }: any, { dispatch }) => {
    const signer = provider.getSigner();
    const aXPHContract = new ethers.Contract(addresses[networkID].AXPH_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const XPHContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;

    const approveAllowance = await aXPHContract.allowance(address, addresses[networkID].Ido_EXCHANGE_ADDRESS);
    const aXPHBalance = await aXPHContract.balanceOf(address);
    const XPHBalance = await XPHContract.balanceOf(address);

    // if()
    return {
      axphAllowance: ethers.utils.formatUnits(String(approveAllowance), 9),
      aXPHBalance: ethers.utils.formatUnits(String(aXPHBalance), 9),
      XPHBalance: ethers.utils.formatUnits(String(XPHBalance), 9),
    };
  },
);

export const exchangeAXPH = createAsyncThunk(
  "ido/exchangeAXPH",
  async ({ provider, address, networkID }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const exchangeContract = new ethers.Contract(
      addresses[networkID].Ido_EXCHANGE_ADDRESS as string,
      ExchangeABI,
      signer,
    ) as any;
    // const aXPHContract = new ethers.Contract(addresses[networkID].AXPH_ADDRESS as string, ierc20ABI, signer) as IERC20;
    // const aXPHBalance = await aXPHContract.balanceOf(address);
    let approveTx;
    try {
      approveTx = await exchangeContract.migrate();
      // console.log("approveTx", approveTx);
      const text = "Exchange";
      const pendingTxnType = "exchange_xph";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        // return {
        //   isSubscriptionSuccessful: true,
        // };
      }
    } catch (e: unknown) {
      dispatch(error((e as any).data.message));
      // return {
      //   isSubscriptionSuccessful: false,
      // };
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  },
);

export const getAmountsOut = createAsyncThunk(
  "swap/getAmountsOut",
  async ({ provider, address, networkID, amountsIn, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const routerContract = new ethers.Contract(addresses[networkID].ROUTER_ADDRESS as string, RouterABI, signer) as any;
    const decimal = topToken != "XPH" ? "18" : "9";
    const amountIn = ethers.utils.parseUnits(amountsIn, decimal);

    let amountsOut;
    if ((topToken == "BNB" && bottomToken == "WBNB") || (topToken == "WBNB" && bottomToken == "BNB")) {
      amountsOut = amountsIn;
    } else {
      const addressArr =
        topToken != "XPH" && bottomToken != "XPH"
          ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
          : topToken == "BUSD" || bottomToken == "BUSD"
          ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
          : [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`BUSD_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ];
      const amounts = await routerContract.getAmountsOut(amountIn, addressArr);

      const amount =
        topToken != "XPH" && bottomToken != "XPH"
          ? amounts[1]
          : topToken == "BUSD" || bottomToken == "BUSD"
          ? amounts[1]
          : amounts[2];

      amountsOut = ethers.utils.formatUnits(String(amount), bottomToken == "XPH" ? "9" : "18");
    }
    return {
      amountsOut,
    };
  },
);

export const getAmountsOutV2 = createAsyncThunk(
  "swap/getAmountsOutV2",
  async ({ provider, address, networkID, amountsIn, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const routerContract = new ethers.Contract(addresses[networkID].ROUTER_ADDRESS as string, RouterABI, signer) as any;
    const decimal = topToken != "XPH" ? "18" : "9";
    const amountIn = ethers.utils.parseUnits(amountsIn, decimal);

    let amountsOut;
    if ((topToken == "BNB" && bottomToken == "WBNB") || (topToken == "WBNB" && bottomToken == "BNB")) {
      amountsOut = amountsIn;
    } else {
      const addressArr =
        topToken != "XPHV2" && bottomToken != "XPHV2"
          ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
          : topToken == "BUSD" || bottomToken == "BUSD"
          ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
          : [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`BUSD_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ];
      const amounts = await routerContract.getAmountsOut(amountIn, addressArr);

      const amount =
        topToken != "XPHV2" && bottomToken != "XPHV2"
          ? amounts[1]
          : topToken == "BUSD" || bottomToken == "BUSD"
          ? amounts[1]
          : amounts[2];

      amountsOut = ethers.utils.formatUnits(String(amount), bottomToken == "XPHV2" ? "9" : "18");
    }
    return {
      amountsOut,
    };
  },
);

export const clearAmount = createAsyncThunk("swap/clearAmount", async () => {
  return {
    amountsOut: "",
    amountsIn: "",
  };
});

export const getAmountsIn = createAsyncThunk(
  "swap/getAmountsIn",
  async ({ provider, networkID, amountsOut, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const routerContract = new ethers.Contract(addresses[networkID].ROUTER_ADDRESS as string, RouterABI, signer) as any;

    const amountOut = ethers.utils.parseUnits(amountsOut, bottomToken == "XPH" ? "9" : "18");
    let addressArr, amounts, amountsIn;
    if ((topToken == "BNB" && bottomToken == "WBNB") || (topToken == "WBNB" && bottomToken == "BNB")) {
      amountsIn = amountsOut;
    } else {
      addressArr =
        topToken != "XPH" && bottomToken != "XPH"
          ? [addresses[networkID][`${topToken}_ADDRESS`], addresses[networkID][`${bottomToken}_ADDRESS`]]
          : topToken == "BUSD" || bottomToken == "BUSD"
          ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
          : [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`BUSD_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ];
      amounts = await routerContract.getAmountsIn(amountOut, addressArr);
      amountsIn = ethers.utils.formatUnits(String(amounts[0]), bottomToken == "XPH" ? "18" : "9");
    }
    return {
      amountsIn,
    };
  },
);

export const getAmountsInV2 = createAsyncThunk(
  "swap/getAmountsInV2",
  async ({ provider, networkID, amountsOut, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const routerContract = new ethers.Contract(addresses[networkID].ROUTER_ADDRESS as string, RouterABI, signer) as any;

    const amountOut = ethers.utils.parseUnits(amountsOut, bottomToken == "XPHV2" ? "9" : "18");
    let addressArr, amounts, amountsIn;
    if ((topToken == "BNB" && bottomToken == "WBNB") || (topToken == "WBNB" && bottomToken == "BNB")) {
      amountsIn = amountsOut;
    } else {
      addressArr =
        topToken != "XPHV2" && bottomToken != "XPHV2"
          ? [addresses[networkID][`${topToken}_ADDRESS`], addresses[networkID][`${bottomToken}_ADDRESS`]]
          : topToken == "BUSD" || bottomToken == "BUSD"
          ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
          : [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`BUSD_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ];
      amounts = await routerContract.getAmountsIn(amountOut, addressArr);
      amountsIn = ethers.utils.formatUnits(String(amounts[0]), bottomToken == "XPHV2" ? "18" : "9");
    }
    return {
      amountsIn,
    };
  },
);

export const getSelectToken = createAsyncThunk(
  "swap/getSelectToken",
  async ({ provider, address, networkID, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider.getSigner();
    let topBal, bottomBal;
    if (topToken == "BNB") {
      const bottomTokenContract = new ethers.Contract(
        addresses[networkID][`${bottomToken}_ADDRESS`] as string,
        ierc20ABI,
        signer,
      ) as IERC20;
      topBal = await provider.getBalance(address);
      bottomBal = await bottomTokenContract.balanceOf(address);
    } else if (bottomToken == "BNB") {
      const topTokenContract = new ethers.Contract(
        addresses[networkID][`${topToken}_ADDRESS`] as string,
        ierc20ABI,
        signer,
      ) as IERC20;
      topBal = await topTokenContract.balanceOf(address);
      bottomBal = await provider.getBalance(address);
    } else {
  

      const topTokenContract = new ethers.Contract(
        addresses[networkID][`${topToken}_ADDRESS`] as string,
        ierc20ABI,
        signer,
      ) as IERC20;

      const bottomTokenContract = new ethers.Contract(
        addresses[networkID][`${bottomToken}_ADDRESS`] as string,
        ierc20ABI,
        signer,
      ) as IERC20;
      topBal = await topTokenContract.balanceOf(address);
      bottomBal = await bottomTokenContract.balanceOf(address);
    }
    topBal = ethers.utils.formatUnits(topBal, topToken == "XPH" || topToken == "XPHV2" ? "9" : "18");
    bottomBal = ethers.utils.formatUnits(bottomBal, bottomToken == "XPH" || bottomToken == "XPHV2" ? "9" : "18");
    return { topBal, bottomBal };
  },
);

export const swapXphWithToken = createAsyncThunk(
  "swap/swapXphWithToken",
  async ({ provider, address, networkID, amountsIn, amountsOut, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    // console.log(provider, address, networkID, amountsIn, amountsOut, type, "yt");

    try {
      const slippage: number = Number(localStorage.getItem("slippage")) || 0.1;
      const signer = provider.getSigner();
      const amountIn = ethers.utils.parseUnits(amountsIn, topToken != "XPH" ? "18" : "9");

      const amountOut = amountsOut * (1 - slippage / 100);
      // const amountOutMin = ethers.utils.parseUnits(String(amountOut), bottomToken == "XPH" ? "9" : "18");
      const amountOutMin = ethers.utils.parseUnits(String(amountOut.toFixed(9)), bottomToken == "XPH" ? "9" : "18");
      const routerContract = new ethers.Contract(
        addresses[networkID].ROUTER_ADDRESS as string,
        RouterABI,
        signer,
      ) as any;
      const WBNBContract = new ethers.Contract(addresses[networkID].WBNB_ADDRESS as string, WBNBABI, signer) as any;

      // const addressArr = type
      //   ? [addresses[networkID].DAI_ADDRESS, addresses[networkID].OHM_ADDRESS]
      //   : [addresses[networkID].OHM_ADDRESS, addresses[networkID].DAI_ADDRESS];
      const addressArr =
        topToken != "XPH" && bottomToken != "XPH"
          ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
          : topToken == "BUSD" || bottomToken == "BUSD"
          ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
          : [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`BUSD_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ];
      let approveTx;
      const deadline: number = Number(localStorage.getItem("deadline")) || 5;

      try {
        if (topToken != "BNB" && bottomToken != "BNB") {
          approveTx = await routerContract.swapExactTokensForTokens(
            amountIn,
            amountOutMin,
            addressArr,
            address,
            Date.now() + 1000 * 60 * deadline,
          );
        } else {
          if (topToken == "BNB" && bottomToken != "WBNB") {
            // console.log("swapExactETHsForTokens");
            // console.log(amountIn, amountOutMin, addressArr);

            // approveTx = await routerContract.swapETHForExactTokens(
            approveTx = await routerContract.swapExactETHForTokens(
              // amountIn,
              amountOutMin,
              addressArr,
              address,
              Date.now() + 1000 * 60 * deadline,
              { value: amountIn },
            );
          } else if (topToken != "WBNB" && bottomToken == "BNB") {
            // console.log("swapExactTokensForETH");

            // console.log(amountIn, amountOutMin, addressArr);
            approveTx = await routerContract.swapExactTokensForETH(
              amountIn,
              amountOutMin,
              addressArr,
              address,
              Date.now() + 1000 * 60 * deadline,
            );
          } else {
            if (topToken == "BNB" && bottomToken == "WBNB") {
              approveTx = await WBNBContract.deposit({ value: amountIn });
            } else if (topToken == "WBNB" && bottomToken == "BNB") {
              approveTx = await WBNBContract.withdraw(amountIn);
            }
          }
        }
        const text = "Swap";
        const pendingTxnType = "Swap_XPH_BUSD";
        if (approveTx) {
          dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
          await approveTx.wait();
          await dispatch(getBalances({ address, provider, networkID }));
          return;
        }
      } catch (e: unknown) {
        dispatch(error((e as IJsonRPCError).message));
        return;
      } finally {
        if (approveTx) {
          dispatch(clearPendingTxn(approveTx.hash));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
  },
);

export const swapXphWithTokenV2 = createAsyncThunk(
  "swap/swapXphWithToken",
  async ({ provider, address, networkID, amountsIn, amountsOut, type, topToken, bottomToken }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    // console.log(provider, address, networkID, amountsIn, amountsOut, type, "yt");

    try {
      const slippage: number = Number(localStorage.getItem("slippage")) || 0.1;
      const signer = provider.getSigner();
      const amountIn = ethers.utils.parseUnits(amountsIn, topToken != "XPHV2" ? "18" : "9");

      const amountOut = amountsOut * (1 - slippage / 100);
      // const amountOutMin = ethers.utils.parseUnits(String(amountOut), bottomToken == "XPH" ? "9" : "18");
      const amountOutMin = ethers.utils.parseUnits(String(amountOut.toFixed(9)), bottomToken == "XPHV2" ? "9" : "18");
      const routerContract = new ethers.Contract(
        addresses[networkID].ROUTER_ADDRESS as string,
        RouterABI,
        signer,
      ) as any;
      const WBNBContract = new ethers.Contract(addresses[networkID].WBNB_ADDRESS as string, WBNBABI, signer) as any;

      // const addressArr = type
      //   ? [addresses[networkID].DAI_ADDRESS, addresses[networkID].OHM_ADDRESS]
      //   : [addresses[networkID].OHM_ADDRESS, addresses[networkID].DAI_ADDRESS];
      const addressArr =
        topToken != "XPHV2" && bottomToken != "XPHV2"
          ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
          : topToken == "BUSD" || bottomToken == "BUSD"
          ? [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ]
          : [
              addresses[networkID][`${topToken == "BNB" ? "WBNB" : topToken}_ADDRESS`],
              addresses[networkID][`BUSD_ADDRESS`],
              addresses[networkID][`${bottomToken == "BNB" ? "WBNB" : bottomToken}_ADDRESS`],
            ];
      let approveTx;
      const deadline: number = Number(localStorage.getItem("deadline")) || 5;

      try {
        if (topToken != "BNB" && bottomToken != "BNB") {
          approveTx = await routerContract.swapExactTokensForTokens(
            amountIn,
            amountOutMin,
            addressArr,
            address,
            Date.now() + 1000 * 60 * deadline,
          );
        } else {
          if (topToken == "BNB" && bottomToken != "WBNB") {
            // console.log("swapExactETHsForTokens");
            // console.log(amountIn, amountOutMin, addressArr);

            // approveTx = await routerContract.swapETHForExactTokens(
            approveTx = await routerContract.swapExactETHForTokens(
              // amountIn,
              amountOutMin,
              addressArr,
              address,
              Date.now() + 1000 * 60 * deadline,
              { value: amountIn },
            );
          } else if (topToken != "WBNB" && bottomToken == "BNB") {
            // console.log("swapExactTokensForETH");

            // console.log(amountIn, amountOutMin, addressArr);
            approveTx = await routerContract.swapExactTokensForETH(
              amountIn,
              amountOutMin,
              addressArr,
              address,
              Date.now() + 1000 * 60 * deadline,
            );
          } else {
            if (topToken == "BNB" && bottomToken == "WBNB") {
              approveTx = await WBNBContract.deposit({ value: amountIn });
            } else if (topToken == "WBNB" && bottomToken == "BNB") {
              approveTx = await WBNBContract.withdraw(amountIn);
            }
          }
        }
        const text = "Swap";
        const pendingTxnType = "Swap_XPH_BUSD";
        if (approveTx) {
          dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
          await approveTx.wait();
          await dispatch(getBalances({ address, provider, networkID }));
          return;
        }
      } catch (e: unknown) {
        dispatch(error((e as IJsonRPCError).message));
        return;
      } finally {
        if (approveTx) {
          dispatch(clearPendingTxn(approveTx.hash));
        }
      }
    } catch (error) {
      console.log("err", error);
    }
  },
);

export interface ISwapSlice {
  busdAllowance: string;
  loading: boolean;
  isWhiteeListed: boolean;
  showBoughtSuccessful: boolean;
  totalAmount: string;
  remaining: string;
  totalSubscription: string;
  axphAllowance: string;
  aXPHBalance: string;
  XPHBalance: string;
  isSubscriptionSuccessful: boolean;
  amountsIn: string;
  amountsOut: string;
  topBal: string;
  bottomBal: string;
}

const initialState: ISwapSlice = {
  busdAllowance: "",
  loading: false,
  isWhiteeListed: false,
  showBoughtSuccessful: false,
  isSubscriptionSuccessful: false,
  totalAmount: "",
  remaining: "",
  totalSubscription: "",
  axphAllowance: "",
  aXPHBalance: "",
  XPHBalance: "",
  amountsIn: "",
  amountsOut: "",
  topBal: "",
  bottomBal: "",
};

const swapSlice = createSlice({
  name: "swap",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllowanceBUSD.pending, state => {
        state.loading = true;
      })
      .addCase(getAllowanceBUSD.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAllowanceBUSD.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getExchangeBalance.pending, state => {
        state.loading = true;
      })
      .addCase(getExchangeBalance.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getExchangeBalance.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(swapXphWithToken.pending, state => {
        state.loading = true;
      })
      .addCase(swapXphWithToken.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(swapXphWithToken.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getAmountsOut.pending, state => {
        state.loading = true;
      })
      .addCase(getAmountsOut.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAmountsOut.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getAmountsOutV2.pending, state => {
        state.loading = true;
      })
      .addCase(getAmountsOutV2.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAmountsOutV2.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getAmountsIn.pending, state => {
        state.loading = true;
      })
      .addCase(getAmountsIn.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAmountsIn.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getAmountsInV2.pending, state => {
        state.loading = true;
      })
      .addCase(getAmountsInV2.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAmountsInV2.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getSelectToken.pending, state => {
        state.loading = true;
      })
      .addCase(getSelectToken.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getSelectToken.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(clearAmount.pending, state => {
        state.loading = true;
      })
      .addCase(clearAmount.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(clearAmount.rejected, (state, { error }) => {
        state.loading = false;
      });
  },
});

export default swapSlice.reducer;
const baseInfo = (state: RootState) => state.swap;
export const getIdoState = createSelector(baseInfo, swap => swap);
