import React from "react";
import "./rewarddata.scss";
import {
  Box,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Trans, t } from "@lingui/macro";
import { Typography } from "@mui/material";
import useYP from "src/hooks/useYP";
import { useAppSelector } from "src/hooks";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useDispatch, useSelector } from "react-redux";
import { success } from "src/slices/MessagesSlice";

interface IRewardData {
  title: string;
  claimTitle: string;
  claimAmount: string;
  cliamType: string;
}

function RewardData({ cliamType, title, claimTitle, claimAmount }: IRewardData) {
  const dispatch = useDispatch();
  const { claimInvite, claimStake, claimReward } = useYP();
  const pendingTransactions = useAppSelector(state => {
    return state.pendingTransactions;
  });

  const handleClaim = async () => {
    if (cliamType == "claimInvite") {
      await claimInvite();
    } else if (cliamType == "claimStake") {
      await claimStake();
    } else {
      await claimReward();
    }
  };

  return (
    <>
      <Paper className="ohm-card reward-data-claim-card">
        <Box className="reward-data-title">
          <Typography variant="h4" style={{ fontWeight: "600" }}>
            {title}
          </Typography>
        </Box>
        <Box display="flex" alignItems="flex-end" justifyContent="space-between">
          <Box>
            <Typography variant="h6" align="left" style={{ color: "#676B74" }}>
              {claimTitle}
            </Typography>
            <Typography variant="h5" align="left" style={{ fontWeight: "500", color: "#181a1d" }}>
              {claimAmount}
            </Typography>
          </Box>
          <LoadingButton
            loadingPosition="end"
            disabled={Number(claimAmount) === 0 || isPendingTxn(pendingTransactions, "claimReward")}
            loading={isPendingTxn(pendingTransactions, "claimReward")}
            // loading
            variant="contained"
            color="primary"
            onClick={handleClaim}
          >
            {txnButtonText(pendingTransactions, "claimReward", t`Claim`)}
          </LoadingButton>
        </Box>
      </Paper>
      {/* <Paper className="ohm-card reward-data-table">
        <TableContainer>
          <Table aria-label="claim profit">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Typography variant="h6">
                    <Trans>Hash</Trans>
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6">
                    <Trans>Time</Trans>
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="h6">
                    <Trans>Amount</Trans>
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">1444233</TableCell>
                <TableCell align="center">1233</TableCell>
                <TableCell align="center">1233</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">3333214214</TableCell>
                <TableCell align="center">1233</TableCell>
                <TableCell align="center">1233</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">1233</TableCell>
                <TableCell align="center">1233</TableCell>
                <TableCell align="center">1233</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper> */}
    </>
  );
}

export default RewardData;
