import { ethers } from "ethers";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { addresses } from "src/constants";
import { IJsonRPCError } from "src/slices/interfaces";
import { clearPendingTxn, fetchPendingTxns } from "src/slices/PendingTxnsSlice";
import { useWeb3Context } from ".";
import { abi as RewardABI } from "../abi/reward.json";
import { abi as YPABI } from "../abi/YPABI.json";
import { error, success } from "../slices/MessagesSlice";
import { t } from "@lingui/macro";
import { fetchAccountSuccess } from "src/slices/AccountSlice";

export default function useYP() {
  const dispatch = useDispatch();
  const [rewardPending, setRewardPending] = useState("0");
  const [stakePending, setStakePending] = useState("0");
  const { address, provider, chainID } = useWeb3Context();
  const [daoPending, setDaoPending] = useState("0");
  const [count, setCount] = useState(0);

  useEffect(() => {
    try {
      initData();
    } catch (error) {}
  }, [address, provider, chainID]);

  const initData = async () => {
    try {
      if (provider && chainID) {
        // const RewardContract = new ethers.Contract(addresses[chainID].StakingCliam,StakeClaimABI,provider)
        if (address) {
          const signer = provider.getSigner();
          const RewardContract = new ethers.Contract(addresses[chainID].Reward, RewardABI, signer);
          const stakeUser = await RewardContract.stakeUser(address);
          // setStakePending(ethers.utils.formatUnits(stakeUser.pending, 9));
          const inviteUser = await RewardContract.inviteUser(address);
          // setRewardPending(ethers.utils.formatUnits(inviteUser.pending, 9));
          const rewardUser = await RewardContract.rewardUser(address);
          // setDaoPending(ethers.utils.formatUnits(rewardUser.pending, 9));
          dispatch(
            fetchAccountSuccess({
              daoPending: ethers.utils.formatUnits(rewardUser.pending, 9),
              rewardPending: ethers.utils.formatUnits(inviteUser.pending, 9),
              stakePending: ethers.utils.formatUnits(stakeUser.pending, 9),
            }),
          );
        }
      }
    } catch (error) {}
  };

  const claimStake = useCallback(async () => {
    let tx; // Migrate
    try {
      const signer = provider.getSigner();
      const migrationContract = new ethers.Contract(addresses[chainID].Reward, RewardABI, signer);
      tx = await migrationContract.claimStake();
      dispatch(fetchPendingTxns({ txnHash: tx.hash, text: "claimReward", type: "claimReward" }));
      await tx.wait();
      return tx;
    } catch (e) {
      const rpcError = e;
      if (rpcError.data) {
        dispatch(error(rpcError.data.message));
      } else {
        dispatch(error(rpcError.message));
      }
    } finally {
      if (tx) {
        await initData();
        dispatch(success(t`Success`));
        dispatch(clearPendingTxn(tx.hash));
      }
    }
  }, [address, provider, chainID]);

  const claimInvite = useCallback(async () => {
    let tx; // Migrate
    try {
      const signer = provider.getSigner();
      const migrationContract = new ethers.Contract(addresses[chainID].Reward, RewardABI, signer);
      tx = await migrationContract.claimInvite();
      dispatch(fetchPendingTxns({ txnHash: tx.hash, text: "claimReward", type: "claimReward" }));
      await tx.wait();
      return tx;
    } catch (e) {
      const rpcError = e;
      if (rpcError.data) {
        dispatch(error(rpcError.data.message));
      } else {
        dispatch(error(rpcError.message));
      }
    } finally {
      if (tx) {
        await initData();
        dispatch(success(t`Success`));

        dispatch(clearPendingTxn(tx.hash));
      }
    }
  }, [address, provider, chainID]);

  const claimReward = useCallback(async () => {
    let tx; // Migrate
    try {
      const signer = provider.getSigner();
      const migrationContract = new ethers.Contract(addresses[chainID].Reward, RewardABI, signer);
      tx = await migrationContract.claimReward();
      dispatch(fetchPendingTxns({ txnHash: tx.hash, text: "claimReward", type: "claimReward" }));
      await tx.wait();
      return tx;
    } catch (e) {
      const rpcError = e;
      if (rpcError.data) {
        dispatch(error(rpcError.data.message));
      } else {
        dispatch(error(rpcError.message));
      }
    } finally {
      if (tx) {
        await initData();
        // location.reload();

        dispatch(success(t`Success`));

        dispatch(clearPendingTxn(tx.hash));
      }
    }
  }, [address, provider, chainID]);

  return { claimInvite, claimStake, claimReward };
}
