import { ethers, BigNumber } from "ethers";
import { addresses } from "../constants";
import { abi as ierc20ABI } from "../abi/IERC20.json";
import { abi as PreSaleABI } from "../abi/PreSale.json";
import { abi as ExchangeABI } from "../abi/AxphMirgation.json";
import { abi as MigrationABI } from "../abi/XPHMigration.json";
import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { error, info } from "./MessagesSlice";
import { IERC20, OlympusStakingv2, StakingHelper } from "src/typechain";
import { IActionValueAsyncThunk, IChangeApprovalAsyncThunk, IJsonRPCError } from "./interfaces";
import { setAll, formatMoney } from "../helpers";
import { RootState } from "src/store";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./PendingTxnsSlice";

// export const approveBSUD = createAsyncThunk(
//   "ido/approveBUSD",
//   async ({ provider, address, networkID }: any, { dispatch }) => {
//     if (!provider) {
//       dispatch(error("Please connect your wallet!"));
//       return;
//     }
//     const signer = provider.getSigner();
//     const busdContract = new ethers.Contract(addresses[networkID].DAO_ADDRESS as string, ierc20ABI, signer) as IERC20;
//     const approveAllowance = await busdContract.allowance(address, addresses[networkID].PreSale_ADDRESS);
//     if (approveAllowance.gt(ethers.utils.parseUnits(String("1000")))) {
//       dispatch(info("Approval completed."));
//       return;
//     }
//     let approveTx;
//     try {
//       approveTx = await busdContract.approve(
//         addresses[networkID].PreSale_ADDRESS,
//         ethers.utils.parseUnits(String(2000)).toString(),
//       );
//       // console.log("approveTx", approveTx);
//       const text = "Approve";
//       const pendingTxnType = "approve_busd";
//       if (approveTx) {
//         dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
//         await approveTx.wait();
//         return {
//           busdAllowance: "2000",
//         };
//       }
//     } catch (e: unknown) {
//       dispatch(error((e as IJsonRPCError).message));
//       return;
//     } finally {
//       if (approveTx) {
//         dispatch(clearPendingTxn(approveTx.hash));
//       }
//     }
//   },
// );

export const purchaseaXPH = createAsyncThunk(
  "ido/purchaseaXPH",
  async ({ provider, address, networkID, amount }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider.getSigner();
    const presaleContract = new ethers.Contract(
      addresses[networkID].PreSale_ADDRESS as string,
      PreSaleABI,
      signer,
    ) as any;
    let approveTx;
    const buyAmount = ethers.utils.parseUnits(String(amount));
    try {
      approveTx = await presaleContract.purchaseaXPH(buyAmount);
      // console.log("approveTx", approveTx);
      const text = "Purchase";
      const pendingTxnType = "purchase_xph";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        try {
          await window.ethereum.request({
            method: "wallet_watchAsset",
            params: {
              type: "ERC20", // Initially only supports ERC20, but eventually more!
              options: {
                address: addresses[networkID].XPH_ADDRESS, // The address that the token is at.
                symbol: "alphaXPH", // A ticker symbol or shorthand, up to 5 chars.
                decimals: 9, // The number of decimals in the token
                // image: XPHLogo, // A string url of the token logo
              },
            },
          });
        } catch (error) {
          console.log(error);
        }
        return {
          isSubscriptionSuccessful: true,
        };
      }
    } catch (e: unknown) {
      dispatch(error((e as any).data.message));
      return {
        isSubscriptionSuccessful: false,
      };
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  },
);

export const getAllowanceBUSD = createAsyncThunk(
  "ido/getAllowanceBUSD",
  async ({ provider, address, networkID }: any, { dispatch }) => {
    const signer = provider.getSigner();
    const busdContract = new ethers.Contract(addresses[networkID].BUSD_ADDRESS as string, ierc20ABI, signer) as IERC20;

    const approveAllowance = await busdContract.allowance(address, addresses[networkID].PreSale_ADDRESS);
    const presaleContract = new ethers.Contract(
      addresses[networkID].PreSale_ADDRESS as string,
      PreSaleABI,
      signer,
    ) as any;
    const isWhiteeListed = await presaleContract.whiteListed(address);
    let _sellAmount = await presaleContract?.saleAmount();
    _sellAmount = _sellAmount && ethers.utils.formatUnits(_sellAmount);
    let _toTalAmount = await presaleContract?.totalAmount();

    _toTalAmount = _toTalAmount && ethers.utils.formatUnits(_toTalAmount);
    // setTotalAmount(formatMoney(Number(_toTalAmount / 5) || 0));
    const totalAmount = formatMoney(Number(_toTalAmount / 5) || 0);
    const remaining = formatMoney(Number((_toTalAmount - _sellAmount) / 5) || 0);
    const totalSubscription = formatMoney(Number(_sellAmount) || 0);
    // setTotalSubscription(_totalSubscription);
    // setRemaining(_remaining);

    return {
      busdAllowance: ethers.utils.formatEther(String(approveAllowance)),
      isWhiteeListed,
      totalAmount,
      remaining,
      totalSubscription,
    };
  },
);

export const approveExchange = createAsyncThunk(
  "ido/approveExchange",
  async ({ provider, address, networkID }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider.getSigner();
    const aXPHContract = new ethers.Contract(addresses[networkID].AXPH_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const aXPHBalance = await aXPHContract.balanceOf(address);
    let approveTx;
    try {
      approveTx = await aXPHContract.approve(addresses[networkID].Ido_EXCHANGE_ADDRESS, aXPHBalance);
      // console.log("approveTx", approveTx);
      const text = "Approve";
      const pendingTxnType = "approve_exchange";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        return;
      }
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  },
);

export const approveMigration = createAsyncThunk(
  "ido/approveMigration",
  async ({ provider, address, networkID, token }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }
    const signer = provider.getSigner();
    const XPHContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const sXPHContract = new ethers.Contract(addresses[networkID].SOHM_ADDRESS as string, ierc20ABI, signer) as IERC20;
    // const aXPHBalance = await aXPHContract.balanceOf(address);
    let approveTx;
    // console.log("token", token);

    try {
      if (token == "XPH") {
        approveTx = await XPHContract.approve(
          addresses[networkID].XPH_MIRGATION_ADDRESS,
          ethers.utils.parseUnits("10000000", 9).toString(),
        );
      } else if (token === "sXPH") {
        approveTx = await sXPHContract.approve(
          addresses[networkID].XPH_MIRGATION_ADDRESS,
          ethers.utils.parseUnits("10000000", 9).toString(),
        );
      }
      // console.log("approveTx", approveTx);
      const text = "Approve";
      const pendingTxnType = "approve_migration";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        return;
      }
    } catch (e: unknown) {
      dispatch(error((e as IJsonRPCError).message));
      return;
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  },
);

export const getExchangeBalance = createAsyncThunk(
  "ido/getExchangeBalance",
  async ({ provider, address, networkID }: any, { dispatch }) => {
    const signer = provider.getSigner();
    const aXPHContract = new ethers.Contract(addresses[networkID].AXPH_ADDRESS as string, ierc20ABI, signer) as IERC20;
    const XPHContract = new ethers.Contract(addresses[networkID].OHM_ADDRESS as string, ierc20ABI, signer) as IERC20;

    const approveAllowance = await aXPHContract.allowance(address, addresses[networkID].Ido_EXCHANGE_ADDRESS);
    const aXPHBalance = await aXPHContract.balanceOf(address);
    const XPHBalance = await XPHContract.balanceOf(address);

    // if()
    return {
      axphAllowance: ethers.utils.formatUnits(String(approveAllowance), 9),
      aXPHBalance: ethers.utils.formatUnits(String(aXPHBalance), 9),
      XPHBalance: ethers.utils.formatUnits(String(XPHBalance), 9),
    };
  },
);

export const exchangeAXPH = createAsyncThunk(
  "ido/exchangeAXPH",
  async ({ provider, address, networkID }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const exchangeContract = new ethers.Contract(
      addresses[networkID].Ido_EXCHANGE_ADDRESS as string,
      ExchangeABI,
      signer,
    ) as any;
    // const aXPHContract = new ethers.Contract(addresses[networkID].AXPH_ADDRESS as string, ierc20ABI, signer) as IERC20;
    // const aXPHBalance = await aXPHContract.balanceOf(address);
    let approveTx;
    try {
      approveTx = await exchangeContract.migrate();
      // console.log("approveTx", approveTx);
      const text = "Exchange";
      const pendingTxnType = "exchange_xph";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        // return {
        //   isSubscriptionSuccessful: true,
        // };
      }
    } catch (e: unknown) {
      dispatch(error((e as any).data.message));
      // return {
      //   isSubscriptionSuccessful: false,
      // };
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  },
);

export const exchangeForXPHV2 = createAsyncThunk(
  "ido/exchangeForXPHV2",
  async ({ provider, address, networkID, token }: any, { dispatch }) => {
    if (!provider) {
      dispatch(error("Please connect your wallet!"));
      return;
    }

    const signer = provider.getSigner();
    const migrationContract = new ethers.Contract(
      addresses[networkID].XPH_MIRGATION_ADDRESS as string,
      MigrationABI,
      signer,
    ) as any;
    // const aXPHContract = new ethers.Contract(addresses[networkID].AXPH_ADDRESS as string, ierc20ABI, signer) as IERC20;
    // const aXPHBalance = await aXPHContract.balanceOf(address);
    let approveTx;
    const tokenAddress = token == "XPH" ? addresses[networkID].OHM_ADDRESS : addresses[networkID].SOHM_ADDRESS;

    try {
      approveTx = await migrationContract.migrate(tokenAddress);
      // console.log("approveTx", approveTx);
      const text = "Exchange";
      const pendingTxnType = "exchange_xph";
      if (approveTx) {
        dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
        await approveTx.wait();
        // return {
        //   isSubscriptionSuccessful: true,
        // };
      }
    } catch (e: unknown) {
      dispatch(error((e as any).data.message));
      console.log("e", e);

      // return {
      //   isSubscriptionSuccessful: false,
      // };
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }
  },
);

interface IIdoSlice {
  busdAllowance: string;
  loading: boolean;
  isWhiteeListed: boolean;
  showBoughtSuccessful: boolean;
  totalAmount: string;
  remaining: string;
  totalSubscription: string;
  axphAllowance: string;
  aXPHBalance: string;
  XPHBalance: string;
  isSubscriptionSuccessful: boolean;
}

const initialState: IIdoSlice = {
  busdAllowance: "",
  loading: false,
  isWhiteeListed: false,
  showBoughtSuccessful: false,
  isSubscriptionSuccessful: false,
  totalAmount: "",
  remaining: "",
  totalSubscription: "",
  axphAllowance: "",
  aXPHBalance: "",
  XPHBalance: "",
};

const idoSlice = createSlice({
  name: "ido",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllowanceBUSD.pending, state => {
        state.loading = true;
      })
      .addCase(getAllowanceBUSD.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getAllowanceBUSD.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(getExchangeBalance.pending, state => {
        state.loading = true;
      })
      .addCase(getExchangeBalance.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getExchangeBalance.rejected, (state, { error }) => {
        state.loading = false;
      })
      .addCase(purchaseaXPH.pending, state => {
        state.loading = true;
      })
      .addCase(purchaseaXPH.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
        state.showBoughtSuccessful = true;
        // state.isSubscriptionSuccessful = true;
      })
      .addCase(purchaseaXPH.rejected, (state, { error }) => {
        state.loading = false;
        state.showBoughtSuccessful = true;
        state.isSubscriptionSuccessful = false;
        console.log(error);
      });
  },
});

export default idoSlice.reducer;
const baseInfo = (state: RootState) => state.ido;
export const getIdoState = createSelector(baseInfo, ido => ido);
