import { i18n } from "@lingui/core";
import { ar, de, en, es, fr, ko, pl, ru, tr, vi, zh } from "make-plural/plurals";
// Declare locales
interface ILocale {
  flag: string;
  plurals: (n: number | string, ord?: boolean) => "zero" | "one" | "two" | "few" | "many" | "other";
}
interface ILocales {
  [locale: string]: ILocale;
}
export const locales: ILocales = {
  en: { flag: "us", plurals: en },
  zh: { flag: "cn", plurals: zh },
  fr: { flag: "fr", plurals: fr },
  ko: { flag: "kr", plurals: ko },
  tr: { flag: "tr", plurals: tr },
  ar: { flag: "ae", plurals: ar },
  es: { flag: "es", plurals: es },
  vi: { flag: "vn", plurals: vi },
  de: { flag: "de", plurals: de },
  ru: { flag: "ru", plurals: ru },
  pl: { flag: "pl", plurals: pl },
};

// Load locale data
for (var [key, locale] of Object.entries(locales)) {
  i18n.loadLocaleData(key, { plurals: locale.plurals });
}

async function fetchLocale(locale: string = "en") {
  const { messages } = await import(/* webpackChunkName: "[request]" */ `../locales/translations/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}
export function selectLocale(locale: string) {
  window.localStorage.setItem("locale", locale);
  return fetchLocale(locale);
}
export function initLocale() {
  var locale = window.localStorage.getItem("locale") as string;

  if (!Object.keys(locales).includes(locale)) locale = "en";
  fetchLocale(locale);
}
