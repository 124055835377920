import {
  Paper,
  Zoom,
  Grid,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React, { useState } from "react";
import { t, Trans } from "@lingui/macro";
import SpeedModal from "./SpeedModal";
import "./claimprofit.scss";
import { Typography } from "@mui/material";
import useStakeClaim from "src/hooks/useStakeClaim";
import { trim } from "src/helpers";
import { getTimeBytimestamp } from "src/helpers";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { useDispatch, useSelector } from "react-redux";
import { success } from "src/slices/MessagesSlice";
import { useAppSelector } from "src/hooks";
import { useEffect } from "react";
// import {useMediaQuery} from ''

function ClaimProfit() {
  const ohmBalance = useAppSelector(state => {
    return state.account.balances.ohm;
  });
  const isAppLoading = useAppSelector(state => state.app.loading);
  const userClaimData = useAppSelector(state => state.account.userClaimData);

  const isSmallerScreen = useMediaQuery("(max-width: 960px)");
  const dispatch = useDispatch();
  const { DoClaim } = useStakeClaim();
  const [zoomed, setZoomed] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [speedTime, setSpeedTime] = useState("");
  const [currentData, setCurrentData] = useState({});
  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const handleModalOpen = (data, index) => {
    setModalOpen(true);
    setSpeedTime("");
    setCurrentData({ ...data, currentIndex: index });
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleSpeedSelect = event => {
    setSpeedTime(event.target.value);
  };

  const handleClaim = async index => {
    await DoClaim(index);
  };
  return (
    <div id="claim-profit-view">
      <Zoom in={true}>
        <>
          <Paper className="ohm-card dr-crad">
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" align="center" style={{ width: "50%" }}>
                DR
              </Typography>
              <Typography variant="h4" align="center" style={{ width: "50%" }}>
                {isAppLoading ? <Skeleton width="100%" /> : <>{trim(Number(ohmBalance), 4)}</>}
              </Typography>
            </Box>
          </Paper>
          {userClaimData &&
            userClaimData.length > 0 &&
            userClaimData.map((data, i) => {
              return (
                <Paper className="ohm-card claim-profit-card">
                  <Grid key={i} container direction="column" spacing={2}>
                    <Grid item>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Box display="flex" alignItems="center" justifyContent="center" width="100%" height="100%">
                            <Typography variant="h2" style={{ fontWeight: "500" }}>
                              {i + 1}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Typography variant="h6" align="center" className="claim-item-title">
                              <Trans>Release Time</Trans>
                            </Typography>
                            <Typography variant="h5" align="center" className="claim-item-amount">
                              {data.lockTime / 86400}
                              <Trans>Day</Trans>
                            </Typography>
                            <Button
                              disabled={data.speedLevel / Math.pow(10, 9) >= 300}
                              variant="contained"
                              color="primary"
                              className="claim-btn"
                              onClick={() => handleModalOpen(data, i)}
                            >
                              {t`Accelerate`}
                            </Button>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                            <Typography variant="h6" align="center" className="claim-item-title">
                              <Trans>Unclaimed</Trans>
                            </Typography>
                            <Typography variant="h5" align="center" className="claim-item-amount">
                              {data.pending / Math.pow(10, 9) < 0.0001
                                ? "0.0000"
                                : trim(data.pending / Math.pow(10, 9), 4)}
                              <Trans>DR</Trans>
                            </Typography>
                            <LoadingButton
                              variant="contained"
                              color="primary"
                              className="claim-btn"
                              loadingPosition="end"
                              sx={{
                                background:
                                  trim(data.pending / Math.pow(10, 9), 4) < 0.0001 ||
                                  isPendingTxn(pendingTransactions, "DoClaim")
                                    ? "rgba(0, 0, 0,0.12)"
                                    : "auto",
                              }}
                              disabled={
                                trim(data.pending / Math.pow(10, 9), 4) < 0.0001 ||
                                isPendingTxn(pendingTransactions, "DoClaim")
                              }
                              loading={isPendingTxn(pendingTransactions, "DoClaim")}
                              onClick={() => {
                                handleClaim(i);
                              }}
                            >
                              {/* {t`Claim`} */}
                              {txnButtonText(pendingTransactions, "DoClaim", t`Claim`)}
                            </LoadingButton>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <Grid container direction="row" spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Box>
                            <Typography variant="h6" align="center" className="claim-item-title">
                              <Trans>Unstable Time</Trans>
                            </Typography>
                            <Typography variant="h5" align="center">
                              {getTimeBytimestamp(Number(data.time * 1000))}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Box>
                            <Typography variant="h6" align="center" className="claim-item-title">
                              <Trans>Profit Balance</Trans>
                            </Typography>
                            <Typography variant="h5" align="center">
                              {trim(data.payout / Math.pow(10, 9), 4)} DR
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                          <Box>
                            <Typography variant="h6" align="center" className="claim-item-title">
                              <Trans>Received</Trans>
                            </Typography>
                            <Typography variant="h5" align="center">
                              {trim(data.claim / Math.pow(10, 9), 4)} DR
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })}
          <SpeedModal
            open={modalOpen}
            handleClose={handleModalClose}
            handleChange={handleSpeedSelect}
            speedTime={speedTime}
            speedLevel={currentData.speedLevel}
            relaseTime={currentData.lockTime / 86400}
            index={currentData.currentIndex}
          />
        </>
      </Zoom>
    </div>
  );
}

export default ClaimProfit;
