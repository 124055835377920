import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t, Trans } from "@lingui/macro";
import { shorten, trim, prettyVestingPeriod } from "../../helpers";
import { redeemBond } from "../../slices/BondSlice";
import BondLogo from "../../components/BondLogo";
import { Box, Button, Link, Paper, TableRow, TableCell, SvgIcon, Slide } from "@material-ui/core";
import { ReactComponent as ArrowUp } from "../../assets/icons/arrow-up.svg";
import { NavLink } from "react-router-dom";
import "./choosebond.scss";
import { Skeleton } from "@material-ui/lab";
import { useWeb3Context, useBonds } from "src/hooks";
import { isPendingTxn, txnButtonTextGeneralPending } from "src/slices/PendingTxnsSlice";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
export function ClaimBondTableData({ userBond, bondDetail, j }) {
  const dispatch = useDispatch();
  const { address, chainID, provider } = useWeb3Context();
  const { bonds, expiredBonds } = useBonds(chainID);

  const bond = userBond;
  const bondName = bond.bond;

  const isAppLoading = useSelector(state => state.app.loading ?? true);

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const vestingPeriod = () => {
    return prettyVestingPeriod(currentBlock, +bondDetail.vesting + +bondDetail.lastBlock);
  };

  async function onRedeem({ autostake, j }) {
    // TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
    let currentBond = [...bonds, ...expiredBonds].find(bnd => bnd.name === bondName);
    await dispatch(redeemBond({ address, bond: currentBond, networkID: chainID, provider, autostake, j }));
  }

  return (
    <TableRow id={`${bondName}--claim`} className="bond-table">
      <TableCell align="left" className="bond-name-cell">
        <BondLogo bond={bond} />
        <div className="bond-name">
          <Typography variant="body1">
            {bond.displayName ? trim(bond.displayName, 4) : <Skeleton width={100} />}
          </Typography>
        </div>
      </TableCell>
      <TableCell align="center">
        {bondDetail.pending ? trim(bondDetail.pending / Math.pow(10, 9), 4) : <Skeleton width={100} />}
      </TableCell>
      <TableCell align="center">
        {bondDetail.payout ? trim(bondDetail.payout / Math.pow(10, 9), 4) : <Skeleton width={100} />}
      </TableCell>
      <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
        {isAppLoading ? <Skeleton /> : vestingPeriod()}
      </TableCell>
      <TableCell align="right">
        <Box display="flex">
          <LoadingButton
            variant="outlined"
            color="primary"
            sx={{
              color: "#93AEBC",
              border: "1px solid rgba(37, 52, 73, 0.5)",
              height: "33px",
              padding: "9px 20px",
              borderColor: "#93AEBC",
              background: "transparent  !important",
              "&:hover": {
                border: "none",
                background: "#93AEBC !important",
                h6: {
                  color: "#F8CC82",
                },
              },
            }}
            // "&.disabled": { background: "yellow" },
            disabled={
              isPendingTxn(pendingTransactions, "redeem_bond_" + bondName) ||
              bondDetail.pending / Math.pow(10, 9) == 0.0
            }
            // disabled
            loading={isPendingTxn(pendingTransactions, "redeem_bond_" + bondName)}
            loadingPosition="end"
            style={{ marginRight: "10px" }}
            onClick={() => onRedeem({ autostake: false, j })}
          >
            <Typography
              variant="h6"
              sx={{
                color:
                  isPendingTxn(pendingTransactions, "redeem_bond_" + bondName) ||
                  bondDetail.pending / Math.pow(10, 9) == 0.0
                    ? "rgba(0, 0, 0, 0.26)"
                    : "#93aebc",
                "&:hover": {
                  color: "#F8CC82",
                },
              }}
            >
              {txnButtonTextGeneralPending(pendingTransactions, "redeem_bond_" + bondName, t`Claim`)}
            </Typography>
          </LoadingButton>
          <LoadingButton
            variant="outlined"
            color="primary"
            sx={{
              color: "#93AEBC",
              border: "1px solid rgba(37, 52, 73, 0.5)",
              height: "33px",
              padding: "9px 20px",
              borderColor: "#93AEBC",
              background: "transparent !important",
              "&:hover": {
                border: "none",
                background: "#93AEBC !important",
                h6: {
                  color: "#F8CC82",
                },
              },
            }}
            disabled={
              isPendingTxn(pendingTransactions, "redeem_bond_" + bondName) ||
              bondDetail.pending / Math.pow(10, 9) == 0.0
            }
            // disabled
            loading={isPendingTxn(pendingTransactions, "redeem_bond_" + bondName)}
            loadingPosition="end"
            onClick={() => onRedeem({ autostake: true, j })}
          >
            <Typography
              variant="h6"
              sx={{
                // color: "#fcfcfc",
                color:
                  isPendingTxn(pendingTransactions, "redeem_bond_" + bondName) ||
                  bondDetail.pending / Math.pow(10, 9) == 0.0
                    ? "rgba(0, 0, 0, 0.26)"
                    : "#93aebc",
                "&:hover": {
                  color: "#F8CC82",
                },
              }}
            >
              {txnButtonTextGeneralPending(pendingTransactions, "redeem_bond_" + bondName, t`Stake`)}
            </Typography>
          </LoadingButton>
        </Box>
      </TableCell>
    </TableRow>
  );
}

export function ClaimBondCardData({ userBond, bondDetail, j }) {
  const dispatch = useDispatch();
  const { address, chainID, provider } = useWeb3Context();
  const { bonds, expiredBonds } = useBonds(chainID);

  const bond = userBond;
  const bondName = bond.bond;

  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const vestingPeriod = () => {
    return prettyVestingPeriod(currentBlock, +bondDetail.vesting + +bondDetail.lastBlock);
  };

  async function onRedeem({ autostake, j }) {
    // TODO (appleseed-expiredBonds): there may be a smarter way to refactor this
    let currentBond = [...bonds, ...expiredBonds].find(bnd => bnd.name === bondName);
    await dispatch(redeemBond({ address, bond: currentBond, networkID: chainID, provider, autostake, j }));
  }

  return (
    <Box id={`${bondName}--claim`} className="claim-bond-data-card bond-data-card" style={{ marginBottom: "30px" }}>
      <Box className="bond-pair">
        <BondLogo bond={bond} />
        <Box className="bond-name">
          <Typography>{bond.displayName ? trim(bond.displayName, 4) : <Skeleton width={100} />}</Typography>
        </Box>
      </Box>

      <div className="data-row">
        <Typography>Claimable</Typography>
        <Typography>
          {bondDetail.pending ? trim(bondDetail.pending / Math.pow(10, 9), 4) : <Skeleton width={100} />}
        </Typography>
      </div>

      <div className="data-row">
        <Typography>Pending</Typography>
        <Typography>
          {bondDetail.payout ? trim(bondDetail.payout / Math.pow(10, 9), 4) : <Skeleton width={100} />}
        </Typography>
      </div>

      <div className="data-row" style={{ marginBottom: "20px" }}>
        <Typography>Fully Vested</Typography>
        <Typography>{vestingPeriod()}</Typography>
      </div>
      <Box display="flex" justifyContent="space-around" alignItems="center" className="claim-bond-card-buttons">
        <LoadingButton
          variant="outlined"
          color="primary"
          sx={{
            color: "#93AEBC",
            border: "1px solid rgba(37, 52, 73, 0.5)",
            height: "33px",
            padding: "9px 20px",
            borderColor: "#93AEBC",
            background: "transparent",
            "&:hover": {
              border: "none",
            },
          }}
          disabled={isPendingTxn(pendingTransactions, "redeem_bond_" + bondName) || bond.pendingPayout == 0.0}
          loading={isPendingTxn(pendingTransactions, "redeem_bond_" + bondName)}
          loadingPosition="end"
          onClick={() => onRedeem({ autostake: false, j })}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#93aebc",
              "&:hover": {
                color: "#F8CC82",
              },
            }}
          >
            {txnButtonTextGeneralPending(pendingTransactions, "redeem_bond_" + bondName, t`Claim`)}
          </Typography>
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          color="primary"
          sx={{
            color: "#93AEBC",
            border: "1px solid rgba(37, 52, 73, 0.5)",
            height: "33px",
            padding: "9px 20px",
            borderColor: "#93AEBC",
            background: "transparent",
            "&:hover": {
              border: "none",
              h5: {
                color: "#F8CC82",
              },
            },
          }}
          onClick={() => onRedeem({ autostake: true, j })}
          disabled={isPendingTxn(pendingTransactions, "redeem_bond_" + bondName) || bond.pendingPayout == 0.0}
          loading={isPendingTxn(pendingTransactions, "redeem_bond_" + bondName)}
          loadingPosition="end"
        >
          <Typography
            variant="h5"
            sx={{
              color: "#93aebc",
              "&:hover": {
                color: "#F8CC82",
              },
            }}
          >
            {txnButtonTextGeneralPending(
              pendingTransactions,
              "redeem_bond_" + bondName + "_autostake",
              t`Claim and Stake`,
            )}
          </Typography>
        </LoadingButton>
      </Box>
    </Box>
  );
}
