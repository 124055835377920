import { useSelector } from "react-redux";
import { Skeleton } from "@material-ui/lab";
import { Box } from "@material-ui/core";
import { trim, formatCurrency } from "../../../../helpers";
import InfoTooltip from "src/components/InfoTooltip/InfoTooltip.jsx";
import { Typography } from "@mui/material";
import { t, Trans } from "@lingui/macro";
import { allBondsMap } from "src/helpers/AllBonds";

export const Metric = props => <Box className={`metric ${props.className}`}>{props.children}</Box>;

Metric.Value = props => <Typography variant="h5">{props.children || <Skeleton type="text" />}</Typography>;

Metric.Title = props => (
  <Typography variant="h6" color="textSecondary">
    {props.children}
  </Typography>
);

export const MarketCap = () => {
  const marketCap = useSelector(state => state.app.marketCap);

  return (
    <Metric className="market">
      <Metric.Title>
        <Trans>Market Cap</Trans>
      </Metric.Title>
      <Metric.Value>{marketCap && formatCurrency(marketCap, 2)}</Metric.Value>
    </Metric>
  );
};

export const OHMPrice = () => {
  const marketPrice = useSelector(state => state.app.marketPrice);

  return (
    <Metric className="price">
      <Metric.Title>
        <Trans>DR Price</Trans>
      </Metric.Title>
      <Metric.Value>{marketPrice && formatCurrency(marketPrice, 2)}</Metric.Value>
    </Metric>
  );
};

export const SOHMPrice = () => {
  const marketPrice = useSelector(state => state.app.marketPrice);

  return (
    <Metric className="price">
      <Metric.Title>
        <Trans>sDR Price</Trans>
      </Metric.Title>
      <Metric.Value>{marketPrice && formatCurrency(marketPrice, 2)}</Metric.Value>
    </Metric>
  );
};

export const CircSupply = () => {
  const circSupply = useSelector(state => state.app.circSupply);
  const totalSupply = useSelector(state => state.app.totalSupply);

  const isDataLoaded = circSupply && totalSupply;

  return (
    <Metric className="circ">
      <Metric.Title>
        <Trans>Circulating Supply (total)</Trans>
      </Metric.Title>
      <Metric.Value>{isDataLoaded && parseInt(circSupply) + " / " + parseInt(totalSupply)}</Metric.Value>
    </Metric>
  );
};

export const TotalSupply = () => {
  const totalSupply = useSelector(state => state.app.totalSupply);

  return (
    <Metric className="circ">
      <Metric.Title>
        <Trans>Total Supply</Trans>
      </Metric.Title>
      <Metric.Value>{totalSupply && parseInt(totalSupply)}</Metric.Value>
    </Metric>
  );
};

export const BackingPerOHM = () => {
  const treasuryBalance = useSelector(state => {
    if (state.bonding.loading == false) {
      let tokenBalances = 0;
      for (const bond in allBondsMap) {
        if (state.bonding[bond]) {
          tokenBalances += state.bonding[bond].purchased;
        }
      }
      if (tokenBalances) {
        return tokenBalances;
      } else {
        return 0;
      }
    }
  });

  const totalSupply = useSelector(state => {
    return state.app.totalSupply || 0;
  });

  const daoBal = useSelector(state => {
    return state.app.daoBal;
  });
  const totalReserves = useSelector(state => {
    return state.app.totalReserves;
  });
  const backingPerOhm = totalReserves / totalSupply;
  return (
    <Metric className="bpo">
      <Metric.Title>
        <Trans>Backing per DR</Trans>
      </Metric.Title>
      <Metric.Value>{!isNaN(backingPerOhm) && formatCurrency(backingPerOhm, 2)}</Metric.Value>
    </Metric>
  );
};

export const CurrentIndex = () => {
  const currentIndex = useSelector(state => state.app.currentIndex);

  return (
    <Metric className="index">
      <Metric.Title>
        <Trans>Current Index</Trans>
        <InfoTooltip
          message={t`The current index tracks the amount of sDR accumulated since the betting started. Basically, how many sDR a person would have if they wagered and held one DR from day one.`}
        />
      </Metric.Title>
      <Metric.Value>{currentIndex && trim(currentIndex, 2) + " DR"}</Metric.Value>
    </Metric>
  );
};

export const WSOHMPrice = () => {
  const wsOhmPrice = useSelector(state => state.app.marketPrice * state.app.currentIndex);

  return (
    <Metric className="wsoprice">
      <Metric.Title>
        <Trans>wsDR Price</Trans>
        <InfoTooltip
          message={
            "wsOHM = sDR * index\n\nThe price of wsOHM is equal to the price of DR multiplied by the current index"
          }
        />
      </Metric.Title>
      <Metric.Value>{wsOhmPrice && formatCurrency(wsOhmPrice, 2)}</Metric.Value>
    </Metric>
  );
};
