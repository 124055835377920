import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Button,
  MenuItem,
} from "@material-ui/core";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { t, Trans } from "@lingui/macro";
import "./speedModal.scss";
import { Select, Typography } from "@mui/material";
import useStakeClaim from "src/hooks/useStakeClaim";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { error, success } from "src/slices/MessagesSlice";
import { useAppSelector } from "src/hooks";

interface ISpeedModal {
  open: boolean;
  handleClose: () => void;
  handleChange: (e: any) => void;
  speedTime: string;
  speedLevel: number;
  relaseTime: number;
  index: number;
}

function SpeedModal({ open, handleClose, speedTime, handleChange, speedLevel, relaseTime, index }: ISpeedModal) {
  const [fee, setFee] = useState(0);
  const dispatch = useDispatch();
  const ypBalance = useAppSelector(state => state.account.ypBalance);
  const [isApproved, setApproved] = useState(false)

  const { upgrade, DoApprove, getApprovedV2 } = useStakeClaim();

  useEffect(() => {
    setFee(Number(speedTime) - speedLevel / Math.pow(10, 9));
  }, [speedTime]);
  // useEffect(() => {
  //   setFee(Number(speedTime));
  // }, [speedTime]);
  const fetchApprove = async (index: any) => {
    const { isApprovedV2 }: any = await getApprovedV2(index)
    setApproved(isApprovedV2 || false)
  }
  useEffect(() => {
    fetchApprove(index)
  }, [index])
  const handleConfirm = async (index: any) => {
    if (ypBalance < fee) {
      dispatch(error(t`you don't have enough YP'`));
      // return
    } else if (isApproved) {
      await upgrade(index, fee);

      handleClose();
    } else {
      await DoApprove(index);
      await fetchApprove(index)
      await upgrade(index, fee);
      handleClose();
    }
  };

  const pendingTransactions = useAppSelector(state => {
    return state.pendingTransactions;
  });
  return (
    <Modal id="speed-modal" open={open} onClose={handleClose} hideBackdrop>
      <Paper className="ohm-card speed-popover">
        <Box display="flex" className="speed-title">
          <IconButton onClick={handleClose}>
            <SvgIcon color="primary" component={XIcon} />
          </IconButton>
          <Typography variant="h3">Speed</Typography>
        </Box>
        <Box className="modal-content">
          <Box display="flex" justifyContent="space-between" className="content-title">
            <Typography variant="h6">
              <Trans>Release Time</Trans>:{relaseTime}
              <Trans>Day</Trans>
            </Typography>
            <Typography variant="h6">{ypBalance}YP</Typography>
          </Box>
          <Box className="select-box">
            <Select labelId="select-label" id="select" value={speedTime} onChange={handleChange}>
              {speedLevel / Math.pow(10, 9) < 15 && (
                <MenuItem value="15">
                  150
                  <Trans> Day</Trans>
                </MenuItem>
              )}
              {speedLevel / Math.pow(10, 9) < 30 && (
                <MenuItem value="30">
                  120
                  <Trans>Day</Trans>
                </MenuItem>
              )}
              {speedLevel / Math.pow(10, 9) < 60 && (
                <MenuItem value="60">
                  100
                  <Trans>Day</Trans>
                </MenuItem>
              )}
              {speedLevel / Math.pow(10, 9) < 100 && (
                <MenuItem value="100">
                  80 <Trans>Day</Trans>
                </MenuItem>
              )}
              {speedLevel / Math.pow(10, 9) < 150 && (
                <MenuItem value="150">
                  60 <Trans>Day</Trans>
                </MenuItem>
              )}
              {speedLevel / Math.pow(10, 9) < 210 && (
                <MenuItem value="210">
                  45 <Trans>Day</Trans>
                </MenuItem>
              )}
              {speedLevel / Math.pow(10, 9) < 300 && (
                <MenuItem value="300">
                  30 <Trans>Day</Trans>
                </MenuItem>
              )}
            </Select>
          </Box>
          <Box className="fee-box" display="flex" alignItems="center" justifyContent="flex-end">
            <Typography variant="h6">fee:{fee > 0 ? fee : 0}YP</Typography>
          </Box>
          {isApproved ? (
            <LoadingButton
              className="confirm-btn"
              variant="contained"
              color="primary"
              onClick={() => handleConfirm(index)}
              disabled={isPendingTxn(pendingTransactions, "upgrade")}
              loading={isPendingTxn(pendingTransactions, "upgrade")}
              loadingPosition="end"
            >
              {txnButtonText(pendingTransactions, "upgrade", t`Confirm`)}
            </LoadingButton>
          ) : (
            <LoadingButton
              className="confirm-btn"
              variant="contained"
              color="primary"
              onClick={() => handleConfirm(index)}
              disabled={isPendingTxn(pendingTransactions, "DoApprove")}
              loading={isPendingTxn(pendingTransactions, "DoApprove")}
              loadingPosition="end"
            >
              {txnButtonText(pendingTransactions, "DoApprove", t`Approve`)}
            </LoadingButton>
          )}
        </Box>
      </Paper>
    </Modal>
  );
}

export default SpeedModal;
