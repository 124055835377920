import { ChangeEvent, Fragment, ReactNode, ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import { formatCurrency, trim } from "../../helpers";
import { Backdrop, Box, Fade, Grid, Paper, Tab, Tabs, TextField, SvgIcon } from "@material-ui/core";
import TabPanel from "../../components/TabPanel";
import BondHeader from "./BondHeader";
import BondRedeem from "./BondRedeem";
import BondPurchase from "./BondPurchase";
import "./bond.scss";
import { useWeb3Context } from "src/hooks/web3Context";
import { Skeleton } from "@material-ui/lab";
import { useAppSelector } from "src/hooks";
import { IAllBondData } from "src/hooks/Bonds";
import { NetworkID } from "src/lib/Bond";
import { Typography } from "@mui/material";
import { ReactComponent as RightIcon } from "src/assets/icons/right.svg";
import { LoadingButton } from "@mui/lab";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import useInvite from "src/hooks/useInvite";

type InputEvent = ChangeEvent<HTMLInputElement>;

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Bond = ({ theme, bond }: { bond: IAllBondData; theme: string }) => {
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();
  const [isInvited, setIsInvited] = useState(false);

  const [slippage, setSlippage] = useState<number | string>(localStorage.getItem("bondSlippage") || 0.1);
  const [recipientAddress, setRecipientAddress] = useState<string>(address);
  const [isBtn, setIsBtn] = useState(localStorage.getItem("isBtnBond") || "btn");
  const { inviteAddr } = useInvite();

  const [view, setView] = useState<number>(0);
  const [quantity, setQuantity] = useState<number | undefined>();
  const [inviteAddress, setInviteAddress] = useState<string>();
  const isActivecode = useAppSelector(state => {
    return state.account.isActivecode;
  });
  const pendingTransactions = useAppSelector(state => {
    return state.pendingTransactions;
  });
  const isBondLoading = useAppSelector<boolean>(state => state.bonding.loading ?? true);
  useEffect(() => {
    setIsInvited(isActivecode);
  }, [isActivecode]);
  const onRecipientAddressChange = (e: InputEvent): void => {
    if (!e.target.value) setRecipientAddress(address);
    return setRecipientAddress(e.target.value);
  };
  const onInviteAddressChange = (e: any): void => {
    if (!e.target.value) setInviteAddress(address);
    return setInviteAddress(e.target.value);
  };
  const onSlippageChange = (value: string, isBtn: string): void => {
    if (isBtn == "ipt") {
      if (value == "0.1" || value == "0.5" || value == "1.0") {
        setIsBtn("btn");
        setSlippage(value);
        localStorage.setItem("bondSlippage", value);
        localStorage.setItem("isBtnBond", "btn");
        return;
      } else {
        localStorage.setItem("isBtnBond", isBtn);
        localStorage.setItem("bondSlippage", value);

        setIsBtn(isBtn);
        return setSlippage(value);
      }
    } else {
      localStorage.setItem("isBtnBond", isBtn);
      localStorage.setItem("bondSlippage", value);

      setIsBtn(isBtn);
      return setSlippage(value);
    }
  };

  const onSlippageBlur = (value: any) => {
    if (value <= 0) {
      setIsBtn("btn");
      setSlippage("0.1");
      localStorage.setItem("slippage", "0.1");
      localStorage.setItem("isBtn", "btn");
    }
  };

  useEffect(() => {
    if (address) setRecipientAddress(address);
  }, [provider, quantity, address]);

  const changeView = (event: ChangeEvent<{}>, value: string | number): void => {
    setView(Number(value));
  };
  const confirmInvite = async () => {
    await inviteAddr(inviteAddress);
  };
  return (
    <Fade in={true} mountOnEnter unmountOnExit>
      <Grid container id="bond-view">
        <Backdrop open={true}>
          <Fade in={true}>
            <Paper className="ohm-card ohm-modal">
              <BondHeader
                bond={bond}
                slippage={slippage}
                recipientAddress={recipientAddress}
                onSlippageChange={onSlippageChange}
                onSlippageBlur={onSlippageBlur}
                onRecipientAddressChange={onRecipientAddressChange}
                theme={theme}
                isBtn={isBtn}
              />

              <Box display="flex" flexDirection="row" className="bond-price-data-row">
                <div className="bond-price-data">
                  <Typography variant="h5" color="textSecondary">
                    <Trans>Bond Price</Trans>
                  </Typography>
                  <Typography variant="h3" className="price" color="primary">
                    {/* <> */}
                    {isBondLoading ? (
                      <Skeleton width="50px" />
                    ) : !(bond as any)?.bondPrice ? (
                      "--"
                    ) : (
                      formatCurrency(bond.bondPrice, 2)
                    )}
                    {/* </> */}
                    {/* <>{isBondLoading ? <Skeleton width="50px" /> : <DisplayBondPrice key={bond.name} bond={bond} />}</> */}
                  </Typography>
                </div>
                <div className="bond-price-data">
                  <Typography variant="h5" color="textSecondary">
                    <Trans>Market Price</Trans>
                  </Typography>
                  <Typography variant="h3" color="primary" className="price">
                    {isBondLoading ? <Skeleton /> : !bond.marketPrice ? "--" : formatCurrency(bond.marketPrice, 2)}
                  </Typography>
                </div>
              </Box>

              <Tabs
                centered
                value={view}
                textColor="primary"
                indicatorColor="primary"
                onChange={changeView}
                aria-label="bond tabs"
              >
                <Tab
                  aria-label="bond-tab-button"
                  label={t({
                    id: "do_bond",
                    comment: "The action of bonding (verb)",
                  })}
                  {...a11yProps(0)}
                />
                <Tab aria-label="redeem-tab-button" label={t`Redeem`} {...a11yProps(1)} />
              </Tabs>

              <TabPanel value={view} index={0}>
                <BondPurchase bond={bond} slippage={slippage} recipientAddress={recipientAddress} theme={theme} />
              </TabPanel>

              <TabPanel value={view} index={1}>
                <BondRedeem bond={bond} />
              </TabPanel>
            </Paper>
          </Fade>
        </Backdrop>
      </Grid>
    </Fade>
  );
};

export const DisplayBondPrice = ({ bond }: { bond: IAllBondData }): ReactElement => {
  const { chainID }: { chainID: NetworkID } = useWeb3Context();

  if (typeof bond.bondPrice === undefined || !bond.isAvailable[chainID] || !bond.bondPrice) {
    return <Fragment>--</Fragment>;
  }

  return (
    <Fragment>
      {new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(bond.bondPrice)}
    </Fragment>
  );
};

export const DisplayBondDiscount = ({ bond }: { bond: IAllBondData }): ReactNode => {
  const { chainID }: { chainID: NetworkID } = useWeb3Context();

  if (typeof bond.bondDiscount === undefined || !bond.isAvailable[chainID]) {
    return <Fragment>--</Fragment>;
  }

  return <Fragment>{bond.bondDiscount && trim(bond.bondDiscount * 100, 2)}%</Fragment>;
};
export default Bond;
