import {
  Box,
  Modal,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { ReactComponent as XIcon } from "../../assets/icons/x.svg";
import { t, Trans } from "@lingui/macro";
import "./bondSettings.scss";
import { Typography } from "@mui/material";

function AdvancedSettings({
  open,
  handleClose,
  slippage,
  recipientAddress,
  onRecipientAddressChange,
  onSlippageChange,
  onSlippageBlur,
  theme,
  isBtn,
}) {
  return (
    <Modal id="hades" open={open} onClose={handleClose} hideBackdrop>
      <Paper className="ohm-card ohm-popover">
        <Box display="flex">
          <IconButton onClick={handleClose}>
            <SvgIcon color="primary" component={XIcon} />
          </IconButton>
          <Typography variant="h3">Hades</Typography>
        </Box>

        <Box className="card-content">
          <InputLabel htmlFor="slippage">
            <Trans>Slippage</Trans>
          </InputLabel>
          <FormControl variant="outlined" color="primary" fullWidth>
            <Box display="flex" alignItems="center" justifyContent="space-around" className="slippage-container">
              <OutlinedInput
                id="slippage"
                value={slippage}
                onChange={e => onSlippageChange(e.target.value, "ipt")}
                onBlur={e => onSlippageBlur(e.target.value)}
                type="number"
                max="100"
                min="0"
                className="slippage-ipt"
                style={{
                  width: "100%",
                  height: "40px",
                }}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </Box>
            <div className="help-text">
              <Typography variant="body2" color="textSecondary">
                <Trans>Transaction may revert if price changes by more than slippage %</Trans>
              </Typography>
            </div>
          </FormControl>

          <InputLabel htmlFor="recipient">
            <Trans>Recipient Address</Trans>
          </InputLabel>
          <FormControl variant="outlined" color="primary" fullWidth>
            <OutlinedInput
              id="recipient"
              value={recipientAddress}
              onChange={onRecipientAddressChange}
              type="text"
              className="recipient-ipt"
            />
            <div className="help-text">
              <Typography variant="body2" color="textSecondary">
                <Trans>Choose recipient address. By default, this is your currently connected address</Trans>
              </Typography>
            </div>
          </FormControl>
        </Box>
      </Paper>
    </Modal>
  );
}

export default AdvancedSettings;
