import React, { useState } from "react";
import {
  Box,
  Modal,
  Paper,
  SvgIcon,
  IconButton,
  FormControl,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  Button,
  Tab,
  Zoom,
  Tabs,
} from "@material-ui/core";
import { Typography } from "@mui/material";
import "./daoRewards.scss";
import { t, Trans } from "@lingui/macro";
import RewardData from "src/components/RewardData/RewardData";
import TabPanel from "src/components/TabPanel";
import PledgeData from "src/components/PledgeData/PledgeData";
import { useEffect } from "react";
import useYP from "src/hooks/useYP";
import { useAppSelector } from "src/hooks";

function a11yProps(index: number) {
  return {
    id: `simple-tab`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function DAORewards() {
  const daoPending = useAppSelector(state => state.account.daoPending);

  const [view, setView] = useState(0);
  const changeView = (_event: React.ChangeEvent<{}>, newView: number) => {
    setView(newView);
  };

  return (
    <div id="dao-rewards-view">
      <Zoom in={true}>
        {/* <RewardData title="Dao Reward" claimTitle="SC可领取数量" claimAmount="1,233.00" /> */}
        <>
          <Box className="tabs-box" display="flex" alignItems="center" justifyContent="flex-start">
            <Tabs
              // centered
              value={view}
              textColor="secondary"
              indicatorColor="secondary"
              className="yp-tab-buttons"
              onChange={changeView}
            >
              <Tab label={t`Invite Earnings`} {...a11yProps(0)} />
              <Tab label={t`Staking Performance`} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={view} index={0}>
            <RewardData
              cliamType="claimReward"
              title={t`Invite Earnings`}
              claimTitle={t`DR Unclaimed`}
              claimAmount={daoPending}
            />
          </TabPanel>
          <TabPanel value={view} index={1}>
            <PledgeData />
          </TabPanel>
        </>
      </Zoom>
    </div>
  );
}

export default DAORewards;
