import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t, Trans } from "@lingui/macro";
import { Box, Button, FormControl, InputAdornment, InputLabel, OutlinedInput, Slide } from "@material-ui/core";
import { prettifySeconds, secondsUntilBlock, shorten, trim } from "../../helpers";
import { bondAsset, calcBondDetails, changeApproval } from "../../slices/BondSlice";
import { useWeb3Context } from "src/hooks/web3Context";
import { isPendingTxn, txnButtonText } from "src/slices/PendingTxnsSlice";
import { Skeleton } from "@material-ui/lab";
import useDebounce from "../../hooks/Debounce";
import { error } from "../../slices/MessagesSlice";
import { DisplayBondDiscount } from "./Bond";
import ConnectButton from "../../components/ConnectButton";
import DownIcon from "../../assets/icons/down.png";
import "./bondPurchase.scss";
import { Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function BondPurchase({ bond, slippage, recipientAddress, theme }) {
  const isLight = theme === "light" ? true : false;
  const SECONDS_TO_REFRESH = 60;
  const dispatch = useDispatch();
  const { provider, address, chainID } = useWeb3Context();
  const [isShowSelect, changeShowSelect] = useState(false);
  const [selVal, setSelVal] = useState(0);
  const [tokenVal, setTokenVal] = useState("USDT");
  const [quantity, setQuantity] = useState("");
  const [secondsToRefresh, setSecondsToRefresh] = useState(SECONDS_TO_REFRESH);
  const currentBlock = useSelector(state => {
    return state.app.currentBlock;
  });

  const isBondLoading = useSelector(state => state.bonding.loading ?? true);

  const pendingTransactions = useSelector(state => {
    return state.pendingTransactions;
  });

  const vestingPeriod = () => {
    const vestingBlock = parseInt(currentBlock) + parseInt(bond.vestingTerm);
    const seconds = secondsUntilBlock(currentBlock, vestingBlock);
    return prettifySeconds(seconds, "day");
  };

  async function onBond() {
    // let maxQ;
    // if (bond.maxBondPrice * bond.bondPrice < Number(bond.balance)) {
    //   // there is precision loss here on Number(bond.balance)
    //   maxQ = bond.maxBondPrice * bond.bondPrice.toString();
    // } else {
    //   maxQ = bond.balance;
    // }
    if (quantity === "") {
      dispatch(error(t`Please enter a value!`));
      // } else if (isNaN(quantity) || quantity <= 0 || quantity > maxQ) {
    } else if (isNaN(quantity)) {
      dispatch(error(t`Please enter a valid value!`));
    } else if (bond.interestDue > 0 || bond.pendingPayout > 0) {
      // const shouldProceed = window.confirm(
      //   t`You have an existing bond. Bonding will reset your vesting period and forfeit rewards. We recommend claiming rewards first or using a fresh wallet. Do you still want to proceed?`,
      // );
      // if (shouldProceed) {
      await dispatch(
        bondAsset({
          value: quantity,
          slippage,
          bond,
          networkID: chainID,
          provider,
          selVal,
          address: recipientAddress || address,
        }),
      );
      // }
    } else {
      await dispatch(
        bondAsset({
          value: quantity,
          slippage,
          bond,
          networkID: chainID,
          provider,
          selVal,
          address: recipientAddress || address,
        }),
      );
      // clearInput();
    }
  }

  const clearInput = () => {
    setQuantity(0);
  };

  // const hasAllowance = useCallback(() => {
  //   return bond.allowance > 0;
  // }, [bond.allowance]);

  const setMax = () => {
    let maxQ;
    if (bond.maxBondPrice * bond.bondPrice < Number(bond.balance)) {
      // there is precision loss here on Number(bond.balance)
      maxQ = bond.maxBondPrice * bond.bondPrice.toString();
    } else {
      maxQ = bond.balance[selVal];
    }
    setQuantity(maxQ);
  };

  const bondDetailsDebounce = useDebounce(quantity, 1000);

  useEffect(() => {
    dispatch(calcBondDetails({ bond, value: quantity, provider, networkID: chainID, selVal }));
  }, [bondDetailsDebounce]);

  useEffect(() => {
    let interval = null;
    if (secondsToRefresh > 0) {
      interval = setInterval(() => {
        setSecondsToRefresh(secondsToRefresh => secondsToRefresh - 1);
      }, 1000);
    } else {
      clearInterval(interval);
      dispatch(calcBondDetails({ bond, value: quantity, provider, networkID: chainID, selVal }));
      setSecondsToRefresh(SECONDS_TO_REFRESH);
    }
    return () => clearInterval(interval);
  }, [secondsToRefresh, quantity]);

  const onSeekApproval = async () => {
    dispatch(changeApproval({ address, bond, provider, networkID: chainID, selVal }));
  };

  const displayUnits = bond.displayUnits;

  const isAllowanceDataLoading = false;
  const handlerSelect = () => {
    changeShowSelect(!isShowSelect);
  };

  const handleChange = (number, str) => {
    setSelVal(number);
    setTokenVal(str);
    changeShowSelect(false);
    clearInput();
    // dispatch(calcBondDetails({ bond, value: quantity, provider, networkID: chainID, selVal: event.target.value }));
  };

  const hasAllowance = useCallback(() => {
    // if (selVal != 0) {
    return !bond.allowance ? false : bond.allowance[selVal] > 0;
    // } else {
    // return true;
    // }
  }, [selVal]);

  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        {!address ? (
          <ConnectButton />
        ) : (
          <>
            {isAllowanceDataLoading ? (
              <Skeleton width="200px" />
            ) : (
              <>
                {/* {!hasAllowance() ? (
                  <div className="help-text">
                    <em>
                      <Typography variant="body1" align="center" color="textSecondary">
                        <Trans>First time bonding</Trans> <b>{bond.displayName}</b>? <br />{" "}
                        <Trans>Please approve Xenophon Dao to use your</Trans> <b>{bond.displayName}</b>{" "}
                        <Trans>for bonding</Trans>.
                      </Typography>
                    </em>
                  </div>
                ) : ( */}
                <FormControl className="ohm-input" variant="outlined" color="primary" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-amount">
                    <Trans>Amount</Trans>
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-amount"
                    type="number"
                    value={quantity}
                    onChange={e => setQuantity(e.target.value)}
                    // startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    labelWidth={55}
                    endAdornment={
                      <InputAdornment position="end">
                        <Button variant="text" onClick={setMax}>
                          <Trans>Max</Trans>
                        </Button>
                        <Box className="selectBox">
                          <Box display="flex" className="selectVal">
                            <Typography variant="h4">{tokenVal}</Typography>
                            {/* <img
                              src={DownIcon}
                              className={["DownIcon", isShowSelect ? "select-caret" : ""].join(" ")}
                            ></img> */}
                          </Box>
                          {/* <Box
                            className={[
                              "selectList",
                              isShowSelect ? "show" : "",
                              !isLight ? "selectListDark" : "",
                            ].join(" ")}
                          >
                            <Box
                              className="selectItem"
                              onClick={() => {
                                handleChange(0, "USDT");
                              }}
                            >
                              <Typography variant="h6">USDT</Typography>
                            </Box>

                            <Box
                              className="selectItem"
                              onClick={() => {
                                handleChange(1, "LP");
                              }}
                            >
                              <Typography variant="h6">LP</Typography>
                            </Box>
                          </Box> */}
                        </Box>
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {/* )} */}
                {!bond.isAvailable[chainID] ? (
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    id="bond-btn"
                    className="transaction-button"
                    disabled={true}
                  >
                    <Trans>Sold Out</Trans>
                  </LoadingButton>
                ) : hasAllowance() ? (
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    id="bond-btn"
                    className="transaction-button"
                    disabled={isPendingTxn(pendingTransactions, "bond_" + bond.name)}
                    loading={isPendingTxn(pendingTransactions, "bond_" + bond.name)}
                    onClick={onBond}
                    loadingPosition="end"
                  >
                    {txnButtonText(pendingTransactions, "bond_" + bond.name, t`Bond`)}
                  </LoadingButton>
                ) : (
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    id="bond-approve-btn"
                    className="transaction-button"
                    disabled={isPendingTxn(pendingTransactions, "approve_" + bond.name)}
                    loading={isPendingTxn(pendingTransactions, "approve_" + bond.name)}
                    loadingPosition="end"
                    onClick={onSeekApproval}
                  >
                    {txnButtonText(pendingTransactions, "approve_" + bond.name, t`Approve`)}
                  </LoadingButton>
                )}
              </>
            )}
          </>
        )}
        {/* {!hasAllowance() && (
          <div className="help-text">
            <em>
              <Typography variant="body2">
                <Trans>Note:</Trans>
                <br />
                <Trans>1. Create LP with a single click using USDT. You don't need to create LP manually.</Trans>
                <br />
                <Trans>
                  2. The "Approve" transaction is only needed when bonding for the first time; subsequent bonding only
                  requires you to perform the "Bond" transaction.
                </Trans>
              </Typography>
            </em>
          </div>
        )} */}
      </Box>

      <Slide direction="left" in={true} mountOnEnter unmountOnExit {...{ timeout: 533 }}>
        <Box className="bond-data">
          <div className="data-row">
            <Typography>
              <Trans>Your Balance</Trans>
            </Typography>{" "}
            <Typography id="bond-balance">
              {/* {isBondLoading ? (
                <Skeleton width="100px" />
              ) : (
                <>
                  {trim(bond.balance, 4)} {displayUnits}
                </>
              )} */}
              {isBondLoading && !bond.balance ? (
                <Skeleton width="100px" />
              ) : (
                <>
                  {!bond.balance ? 0 : trim(bond.balance[selVal], 4)} {selVal == "0" ? "USDT" : "LP"}{" "}
                  {/* {bond.displayUnits} */}
                </>
              )}
            </Typography>
          </div>

          <div className={`data-row`}>
            <Typography>
              <Trans>You Will Get</Trans>
            </Typography>
            <Typography id="bond-value-id" className="price-data">
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.bondQuote, 4) || "0"} DR`}
            </Typography>
          </div>

          <div className={`data-row`}>
            <Typography>
              <Trans>Max You Can Buy</Trans>
            </Typography>
            <Typography id="bond-value-id" className="price-data">
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.maxBondPrice, 4) || "0"} DR`}
            </Typography>
          </div>

          <div className="data-row">
            <Typography>
              <Trans>ROI</Trans>
            </Typography>
            <Typography>
              {isBondLoading ? <Skeleton width="100px" /> : <DisplayBondDiscount key={bond.name} bond={bond} />}
            </Typography>
          </div>

          <div className="data-row">
            <Typography>
              <Trans>Debt Ratio</Trans>
            </Typography>
            <Typography>
              {isBondLoading ? <Skeleton width="100px" /> : `${trim(bond.debtRatio / 10000000, 2)}%`}
            </Typography>
          </div>

          <div className="data-row">
            <Typography>
              <Trans>Vesting Term</Trans>
            </Typography>
            <Typography>{isBondLoading ? <Skeleton width="100px" /> : vestingPeriod()}</Typography>
          </div>

          {recipientAddress !== address && (
            <div className="data-row">
              <Typography>
                <Trans>Recipient</Trans>{" "}
              </Typography>
              <Typography>{isBondLoading ? <Skeleton width="100px" /> : shorten(recipientAddress)}</Typography>
            </div>
          )}
        </Box>
      </Slide>
    </Box>
  );
}

export default BondPurchase;
