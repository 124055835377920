import { ethers } from "ethers";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { addresses } from "src/constants";
import { IJsonRPCError } from "src/slices/interfaces";
import { clearPendingTxn, fetchPendingTxns } from "src/slices/PendingTxnsSlice";
import { useWeb3Context } from ".";
import { abi as StakeClaimABI } from "../abi/StakeClaim.json";
import { abi as StakeClaimABIV2 } from "../abi/StakeClaimV2.json";
import { abi as YPABI } from "../abi/YPABI.json";
import { error, success } from "../slices/MessagesSlice";
import { t } from "@lingui/macro";
import { fetchAccountSuccess } from "src/slices/AccountSlice";

export default function useStakeClaim() {
  const dispatch = useDispatch();
  const [isApproved, setApproved] = useState(false);
  const { address, provider, chainID } = useWeb3Context();
  const [userData, setData] = useState([]);
  const [ypBalance, setYpBalance] = useState(0);

  useEffect(() => {
    try {
      initData();
    } catch (error) { }
  }, [address, provider, chainID]);

  const initData = async () => {
    try {
      if (provider && chainID) {
        if (address) {
          const signer = provider.getSigner();
          const StakeClaimContract = new ethers.Contract(addresses[chainID].StakingCliamData, StakeClaimABIV2, signer);
          const userClaimData = await StakeClaimContract.userData(address);
          const YPcontract = new ethers.Contract(addresses[chainID].YP, YPABI, signer);
          const balance = await YPcontract.balanceOf(address);
          const allowance = await YPcontract.allowance(address, addresses[chainID].StakingCliam);
          const allowanceV2 = await YPcontract.allowance(address, addresses[chainID].StakingCliamV2);
          // setData(userData);
          // setApproved(Number(allowance) > 0);
          // setYpBalance(ethers.utils.formatUnits(balance, 9));
          dispatch(
            fetchAccountSuccess({
              userClaimData,
              isStakeClaimApproved: Number(allowance) > 0 && Number(allowanceV2) > 0,
              ypBalance: ethers.utils.formatUnits(balance, 9),
            }),
          );
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const DoClaim = useCallback(
    async index => {
      let tx; // Migrate
      try {
        const signer = provider.getSigner();
        const StakeClaimDataContract = new ethers.Contract(addresses[chainID].StakingCliamData, StakeClaimABIV2, signer);
        const StakeClaimV2Contract = new ethers.Contract(addresses[chainID].StakingCliamV2, StakeClaimABIV2, signer);
        const StakeClaimV1Contract = new ethers.Contract(addresses[chainID].StakingCliam, StakeClaimABI, signer);
        const userClaimData = await StakeClaimDataContract.userData(address);
        const isOld = userClaimData[index].old
        const isTemp = Number(userClaimData[index].pending) == Number(userClaimData[index].payout)
        if (isOld) {
          if (isTemp) {
            tx = await StakeClaimV2Contract.claim(index);
          } else {
            tx = await StakeClaimV1Contract.claim(index);
          }
        } else {
          tx = await StakeClaimV2Contract.claim(index);
        }
        dispatch(fetchPendingTxns({ txnHash: tx.hash, text: "DoClaim", type: "DoClaim" }));
        await tx.wait();
        return tx;
      } catch (e) {
        const rpcError = e;
        if (rpcError.data) {
          dispatch(error(rpcError.data.message));
        } else {
          dispatch(error(rpcError.message));
        }
      } finally {
        if (tx) {
          await initData();
          dispatch(success(t`Success`));
          // location.reload();
          dispatch(clearPendingTxn(tx.hash));
        }
      }
    },
    [address, provider, chainID],
  );

  const upgrade = useCallback(
    async (index, fee) => {
      let tx; // Migrate
      try {
        const signer = provider.getSigner();
        const StakeClaimV2Contract = new ethers.Contract(addresses[chainID].StakingCliamV2, StakeClaimABIV2, signer);
        const StakeClaimV1Contract = new ethers.Contract(addresses[chainID].StakingCliam, StakeClaimABI, signer);
        const userClaimData = await StakeClaimV2Contract.userData(address);
        const isOld = userClaimData[index].old
        if (isOld) {
          tx = await StakeClaimV1Contract.upgrade(index, ethers.utils.parseUnits(fee.toString(), 9));
        } else {
          tx = await StakeClaimV2Contract.upgrade(index, ethers.utils.parseUnits(fee.toString(), 9));
        }
        dispatch(fetchPendingTxns({ txnHash: tx.hash, text: "upgrade", type: "upgrade" }));
        await tx.wait();
        return tx;
      } catch (e) {
        const rpcError = e;
        if (rpcError.data) {
          dispatch(error(rpcError.data.message));
        } else {
          dispatch(error(rpcError.message));
        }
      } finally {
        if (tx) {
          await initData();
          dispatch(success(t`Success`));
          // location.reload();

          dispatch(clearPendingTxn(tx.hash));
        }
      }
    },
    [address, provider, chainID],
  );

  const DoApprove = useCallback(async (index) => {
    let tx; // Migrate
    const { isOld } = await getApprovedV2(index)
    try {
      const signer = provider.getSigner();
      const YPcontract = new ethers.Contract(addresses[chainID].YP, YPABI, signer);
      if (isOld) {
        tx = await YPcontract.approve(addresses[chainID].StakingCliam, ethers.utils.parseEther("99999999999"));
      } else {
        tx = await YPcontract.approve(addresses[chainID].StakingCliamV2, ethers.utils.parseEther("99999999999"));
      }
      dispatch(fetchPendingTxns({ txnHash: tx.hash, text: "DoApprove", type: "DoApprove" }));
      await tx.wait();
      return tx;
    } catch (e) {
      const rpcError = e;
      if (rpcError.data) {
        dispatch(error(rpcError.data.message));
      } else {
        dispatch(error(rpcError.message));
      }
    } finally {
      if (tx) {
        await initData();
        dispatch(success(t`Success`));
        location.reload();
        dispatch(clearPendingTxn(tx.hash));
      }
    }
  }, [address, provider, chainID]);

  const getApprovedV2 = async (index) => {
    if (provider && chainID && address) {
      const signer = provider.getSigner();
      const YPcontract = new ethers.Contract(addresses[chainID].YP, YPABI, signer);
      const allowance = await YPcontract.allowance(address, addresses[chainID].StakingCliam);
      const allowanceV2 = await YPcontract.allowance(address, addresses[chainID].StakingCliamV2);
      const StakeClaimV2Contract = new ethers.Contract(addresses[chainID].StakingCliamV2, StakeClaimABIV2, signer);
      const userClaimData = await StakeClaimV2Contract.userData(address);
      const isOld = userClaimData[index]?.old
      const isApprovedV2 = isOld ? Number(allowance) > 0 : Number(allowanceV2) > 0
      return { isOld, isApprovedV2 }
    }
    return { isOld: false, isApprovedV2: false }
  }
  return { DoClaim, upgrade, DoApprove, getApprovedV2 };
}
